import { ChainId } from '@uniswap/sdk-core'
import { nativeOnChain, WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { getChainIdFromName } from 'hooks/useSyncChainQuery'
import { Chain } from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'

export function getNativeTokenDBAddress(chain: Chain): string | undefined {
  const pageChainId = getChainIdFromName(chain)
  if (pageChainId === undefined) {
    return undefined
  }
  switch (pageChainId) {
    case ChainId.COSTON2:
      // case ChainId.FLARE:
      // case ChainId.SONGBIRD:
      return WRAPPED_NATIVE_CURRENCY[pageChainId]?.address
    default:
      return undefined
  }
}
