import { useTranslation } from 'react-i18next'

export interface MenuSection {
  title: string
  items: MenuItem[]
  closeMenu?: () => void
}

export interface MenuItem {
  label: string
  href: string
  internal?: boolean
  overflow?: boolean
  closeMenu?: () => void
}

export const useMenuContent = (): MenuSection[] => {
  const { t } = useTranslation()
  return [
    {
      title: t('Explore'),
      items: [
        { label: t('Swap'), href: '/swap', internal: true },
        { label: t('Pools'), href: '/explore/pools', internal: true },
        { label: t('Vote Pools'), href: '/explore/vote', internal: true },
        { label: t('Tokens'), href: '/explore/tokens', internal: true },
        // { label: t('Transactions'), href: '/explore/transactions', internal: true },
        { label: t('Stake'), href: '/stake', internal: true },
        { label: t('Govern'), href: '/vote', internal: true },
        { label: t('Incentivize'), href: '/incentivize', internal: true},
        { label: t('Get Listed'), href: '/list', internal: true },
      ],
    },
    {
      title: t('Apps'),
      items: [
        { label: t('V1 DEX'), href: 'https://dex.oracleswap.io/en/swap' },
        { label: t('FTSO Earn'), href: 'https://earn.oracleswap.io/' },
        { label: t('Docs'), href: 'https://docs.oracleswap.io/' },
        { label: t('SGB Charts'), href: 'https://www.geckoterminal.com/songbird/oracleswap/pools' },

      ],
    },
    {
      title: t('Flare'),
      items: [
        { label: t('Flare Network'), href: 'https://flare.network/' },
        { label: t('Flare Portal'), href: 'https://portal.flare.network/' },
        { label: t('Flare Docs'), href: 'https://docs.flare.network/' },
        { label: t('Flare Explorer'), href: 'https://flare-explorer.flare.network/' },
        { label: t('Flare Monitor'), href: 'https://flare-ftso-monitor.flare.network/data-providers' },
        { label: t('Songbird Explorer'), href: 'https://songbird-explorer.flare.network/' },
        { label: t('Songbird Monitor'), href: 'https://songbird-ftso-monitor.flare.network/data-providers' },

      ],
    },
  ]
}
