import styled from 'styled-components'

import HolidayUniIcon from './HolidayUniIcon'
import mainLogo from "assets/images/logo.svg"

// ESLint reports `fill` is missing, whereas it exists on an SVGProps type
export type SVGProps = React.SVGProps<SVGSVGElement> & {
  fill?: string
  height?: string | number
  width?: string | number
  gradientId?: string
  clickable?: boolean
  onClick?: () => void
}

export const UniIcon = ({ clickable, onClick, ...props }: SVGProps) => (
  <Container clickable={clickable} onClick={() => clickable ? onClick && onClick() : null}>
    <img width={40} height={30} src={mainLogo} alt='logo' />
  </Container>
)

export const OSLogo = ({ clickable, onClick, ...props }: SVGProps) => (
  <Container clickable={clickable}>
    <img width={props.width} height={props.height} src={mainLogo} alt='logo' />
  </Container>
)

const Container = styled.div<{ clickable?: boolean }>`
  position: relative;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
`
