import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { sendAnalyticsEvent } from 'analytics'
import ms from 'ms'
import { logSwapQuoteRequest } from 'tracing/swapFlowLoggers'
import { trace } from 'tracing/trace'

import { GetQuickQuoteArgs, PreviewTradeResult, QuickRouteResponse, QuoteState, RouterPreference } from './types'
import IUniswapV2Router02Json from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { isExactInput, transformQuickRouteToTrade } from './utils'
import { ChainId, V2_ROUTER_ADDRESSES, WETH9 } from '@uniswap/sdk-core'
import { Contract, ethers } from 'ethers'
import { RPC_PROVIDERS } from 'constants/providers'
import { formatEther } from 'ethers/lib/utils'

const UNISWAP_API_URL = process.env.REACT_APP_UNISWAP_API_URL
const UNISWAP_GATEWAY_DNS_URL = process.env.REACT_APP_UNISWAP_GATEWAY_DNS
if (UNISWAP_API_URL === undefined || UNISWAP_GATEWAY_DNS_URL === undefined) {
  throw new Error(`UNISWAP_API_URL and UNISWAP_GATEWAY_DNS_URL must be a defined environment variable`)
}

const { abi: IUniswapV2Router02ABI } = IUniswapV2Router02Json

export const quickRouteApi = createApi({
  reducerPath: 'quickRouteApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (build) => ({
    getQuickRoute: build.query<PreviewTradeResult, GetQuickQuoteArgs>({
      queryFn(args, _api, _extraOptions, fetch) {
        return trace({ name: 'QuickRoute', op: 'quote.quick_route' }, async (trace) => {
          logSwapQuoteRequest(args.tokenInChainId, RouterPreference.API, true)
          const {
            tokenInAddress,
            tokenInDecimals,
            tokenInSymbol,
            tokenInName,
            tokenInChainId,
            tokenOutAddress,
            tokenOutDecimals,
            tokenOutSymbol,
            tokenOutName,
            tokenOutChainId,
            amount,
            tradeType,
            pair
          } = args

          const type = isExactInput(tradeType) ? 'EXACT_IN' : 'EXACT_OUT'

          const provider = RPC_PROVIDERS[tokenInChainId as number]
          const routerContract = new Contract(V2_ROUTER_ADDRESSES[tokenInChainId], IUniswapV2Router02ABI, provider)

          const requestBody = {
            tokenInChainId,
            tokenInAddress: tokenInAddress === "ETH" ? WETH9[tokenInChainId].address : tokenInAddress,
            tokenOutChainId,
            tokenOutAddress: tokenOutAddress === "ETH" ? WETH9[tokenOutChainId].address : tokenOutAddress,
            amount,
            tradeType: type,
          }


          try {
            let amountsOut;
            if (isExactInput(tradeType)) {
              const res = await routerContract.getAmountsOut(amount, [requestBody.tokenInAddress, requestBody.tokenOutAddress]);
              amountsOut = res[1]
            } else {
              const res = await routerContract.getAmountsIn(amount, [requestBody.tokenInAddress, requestBody.tokenOutAddress]);
              amountsOut = res[0]
            }
            const quickRouteResponse = {
              tokenIn: {
                address: requestBody.tokenInAddress,
                decimals: tokenInDecimals,
                symbol: tokenInSymbol,
                name: tokenInName,
              },
              tokenOut: {
                address: requestBody.tokenOutAddress,
                decimals: tokenOutDecimals,
                symbol: tokenOutSymbol,
                name: tokenOutName,
              },
              tradeType: type,
              quote: {
                amount: amountsOut,
                path: `[v2] 100.00% = ${pair}`
              }
            }
            const previewTrade = transformQuickRouteToTrade(args, quickRouteResponse as QuickRouteResponse)
            return {
              data: {
                state: QuoteState.SUCCESS,
                trade: previewTrade,
                latencyMs: trace.now(),
              },
            }
          } catch (error) {
            console.error('Error estimating output amount:', error.message);
            return {
              data: { state: QuoteState.NOT_FOUND, latencyMs: trace.now() },
            }
          }

        })
      },
      keepUnusedDataFor: ms(`10s`),
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
})

export const { useGetQuickRouteQuery } = quickRouteApi
export const useGetQuickRouteQueryState = quickRouteApi.endpoints.getQuickRoute.useQueryState
