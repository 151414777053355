export const ORACLE_SWAP_ERC20_LIST = "https://raw.githubusercontent.com/oracle-swap/default-token-list/main/tokens.json"

export const FTSO_PROVIDER_LIST = "https://api.github.com/repos/TowoLabs/ftso-signal-providers/contents/bifrost-wallet.providerlist.json"

export const UNSUPPORTED_LIST_URLS: string[] = []

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [ORACLE_SWAP_ERC20_LIST]
export const DEFAULT_INACTIVE_LIST_URLS: string[] = []

export const DEFAULT_LIST_OF_LISTS: string[] = [...DEFAULT_ACTIVE_LIST_URLS]
