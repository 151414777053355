import { Currency, CurrencyAmount, Percent, TradeType, V2_ROUTER_ADDRESS, V2_ROUTER_ADDRESSES } from '@uniswap/sdk-core'
import { ApprovalState, useApproval } from 'lib/hooks/useApproval'
import { useCallback, useMemo } from 'react'
import { Trade } from '@uniswap/router-sdk'

import { useHasPendingApproval, useTransactionAdder } from '../state/transactions/hooks'
import { TransactionType } from '../state/transactions/types'
import { useWeb3React } from '@web3-react/core'
import { InterfaceTrade } from 'state/routing/types'
export { ApprovalState } from 'lib/hooks/useApproval'

function useGetAndTrackApproval(getApproval: ReturnType<typeof useApproval>[1]) {
  const addTransaction = useTransactionAdder()

  return useCallback(() => {
    return getApproval && getApproval()
      .then((pending) => {
        if (pending) {
          const { response, tokenAddress, spenderAddress: spender, amount } = pending
          addTransaction(response, {
            type: TransactionType.APPROVAL,
            tokenAddress,
            spender,
            amount: amount.quotient.toString(),
          })
        }
      })
      .catch((error) => {
      })
  }, [addTransaction, getApproval])
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount<Currency>,
  spender?: string
): [ApprovalState, () => Promise<void>] {
  const [approval, getApproval] = useApproval(amountToApprove, spender, useHasPendingApproval)
  return [approval, useGetAndTrackApproval(getApproval)]
}

export function useApproveCallbackFromTrade(
  trade: InterfaceTrade | undefined,
  allowedSlippage: Percent
) {
  const { chainId } = useWeb3React()
  const amountToApprove = useMemo(
    () => (trade && trade.inputAmount.currency.isToken ? trade.maximumAmountIn(allowedSlippage) : undefined),
    [trade, allowedSlippage]
  )
  return useApproveCallback(amountToApprove, V2_ROUTER_ADDRESS)
}

