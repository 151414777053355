import { ChainId } from '@uniswap/sdk-core'

const BLOCK_EXPLORER_PREFIXES: { [chainId: number]: string } = {
  [ChainId.COSTON2]: 'https://coston2-explorer.flare.network',
}

const FTSO_EXPLORER_PREFIXES: { [chainId: number]: string } = {
  [ChainId.COSTON2]: 'https://coston2-ftso-monitor.flare.network/price?',
  [ChainId.FLARE]: 'https://flare-ftso-monitor.flare.network/price?',
  [ChainId.SONGBIRD]: 'https://songbird-ftso-monitor.flare.network/price?',
}


export enum ExplorerDataType {
  TRANSACTION = 'transaction',
  TOKEN = 'token',
  ADDRESS = 'address',
  BLOCK = 'block',
  NATIVE = 'native',
}

/**
 * Return the explorer link for the given data and data type
 * @param chainId the ID of the chain for which to return the data
 * @param data the data to return a link for
 * @param type the type of the data
 */
export function getExplorerLink(chainId: number, data: string, type: ExplorerDataType): string {
  const prefix = BLOCK_EXPLORER_PREFIXES[chainId] ?? 'https://coston2-explorer.flare.network'

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${prefix}/tx/${data}`

    case ExplorerDataType.TOKEN:
      return `${prefix}/token/${data}`

    case ExplorerDataType.BLOCK:
      return `${prefix}/block/${data}`

    case ExplorerDataType.ADDRESS:
      return `${prefix}/address/${data}`
    default:
      return `${prefix}`
  }
}

export function getFtsoExplorerLink(chainId: number, provider: string): string {
  const prefix = FTSO_EXPLORER_PREFIXES[chainId] ?? FTSO_EXPLORER_PREFIXES[ChainId.COSTON2]

  return `${prefix}?providerAddress=${provider}`
}
