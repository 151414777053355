import { TransactionResponse } from '@ethersproject/abstract-provider'
import { BigNumber } from '@ethersproject/bignumber'
import { CustomUserProperties, SwapEventName } from '@uniswap/analytics-events'
import { Percent, V2_ROUTER_ADDRESS, V2_ROUTER_ADDRESSES } from '@uniswap/sdk-core'
import { Trade as LegacyRouter } from '@uniswap/v2-sdk'
import { FlatFeeOptions, SwapRouter } from '@uniswap/universal-router-sdk'
import { FeeOptions, toHex } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { sendAnalyticsEvent, useTrace } from 'analytics'
import { getConnection } from 'connection'
import { useTotalBalancesUsdForAnalytics } from 'graphql/data/apollo/TokenBalancesProvider'
import { useGetTransactionDeadline } from 'hooks/useTransactionDeadline'
import { t } from 'i18n'
import useBlockNumber from 'lib/hooks/useBlockNumber'
import { formatCommonPropertiesForTrade, formatSwapSignedAnalyticsEventProperties } from 'lib/utils/analytics'
import { useCallback } from 'react'
import { ClassicTrade, InterfaceTrade, TradeFillType } from 'state/routing/types'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { trace } from 'tracing/trace'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { UserRejectedRequestError, WrongChainError } from 'utils/errors'
import isZero from 'utils/isZero'
import { didUserReject, swapErrorToUserReadableMessage } from 'utils/swapErrorToUserReadableMessage'
import { getWalletMeta } from 'utils/walletMeta'
import { PermitSignature } from './usePermitAllowance'
import { useV2RouterContract } from './useContract'

/** Thrown when gas estimation fails. This class of error usually requires an emulator to determine the root cause. */
class GasEstimationError extends Error {
  constructor() {
    super(t`Your swap is expected to fail.`)
  }
}

/**
 * Thrown when the user modifies the transaction in-wallet before submitting it.
 * In-wallet calldata modification nullifies any safeguards (eg slippage) from the interface, so we recommend reverting them immediately.
 */
class ModifiedSwapError extends Error {
  constructor() {
    super(
      t`Your swap was modified through your wallet. If this was a mistake, please cancel immediately or risk losing your funds.`
    )
  }
}

interface SwapOptions {
  slippageTolerance: Percent
  permit?: PermitSignature
  feeOptions?: FeeOptions
  flatFeeOptions?: FlatFeeOptions
}

export function useUniversalRouterSwapCallback(
  trade: InterfaceTrade | undefined,
  fiatValues: { amountIn?: number; amountOut?: number; feeUsd?: number },
  options: SwapOptions
) {
  const { account, chainId, provider, connector } = useWeb3React()
  const analyticsContext = useTrace()
  const blockNumber = useBlockNumber()
  const getDeadline = useGetTransactionDeadline()
  const isAutoSlippage = useUserSlippageTolerance()[0] === 'auto'
  const portfolioBalanceUsd = useTotalBalancesUsdForAnalytics()
  const v2RouterContract = useV2RouterContract()

  return useCallback(
    (): Promise<{ type: TradeFillType.Classic; response: TransactionResponse; deadline?: BigNumber }> =>
      trace({ name: 'Swap (Classic)', op: 'swap.classic' }, async (trace) => {
        try {
          if (!account) throw new Error('missing account')
          if (!chainId) throw new Error('missing chainId')
          if (!provider) throw new Error('missing provider')
          if (!trade) throw new Error('missing trade')
          if (!v2RouterContract) throw new Error('missing v2router contract')
          const connectedChainId = await provider.getSigner().getChainId()
          if (chainId !== connectedChainId) throw new WrongChainError()

          const deadline = await getDeadline()
          trace.setData('slippageTolerance', options.slippageTolerance.toFixed(2))

          const v2RouterAddress = V2_ROUTER_ADDRESSES[chainId];

          // const amountIn = trade.inputAmount;
          const amountIn = BigNumber.from(trade.inputAmount.quotient.toString());
          const slippageNum = Number(options.slippageTolerance.asFraction.multiply(100).toSignificant(2))
          const amountOutMin = BigNumber.from(trade.outputAmount.quotient.toString()); // Adjust for slippage tolerance
          const path = [trade.inputAmount.currency.wrapped.address, trade.outputAmount.currency.wrapped.address]; // Adjust according to trade details
          const to = account; // The address that receives the output tokens
          const deadlineTime = Math.floor(Date.now() / 1000) + 60 * 20; // Set a default 20 minutes
          const isNative = trade.inputAmount.currency.isNative
          const isNativeOut = trade.outputAmount.currency.isNative

          let txData: any
          if (isNative) {
            txData = v2RouterContract.interface.encodeFunctionData('swapExactETHForTokens', [
              amountOutMin,
              path,
              to,
              deadline
            ]);
          } else if (!isNativeOut) {
            txData = v2RouterContract.interface.encodeFunctionData('swapExactTokensForTokens', [
              amountIn,
              amountOutMin,
              path,
              to,
              deadline
            ]);
          } else {
            txData = v2RouterContract.interface.encodeFunctionData('swapExactTokensForETH', [
              amountIn,
              amountOutMin,
              path,
              to,
              deadline
            ]);
          }

          const tx = {
            from: account,
            to: v2RouterAddress,
            data: txData,
            value: isNative ? amountIn : '0x0', // No ETH needed for ERC20 to ERC20 swap
          };

          let gasLimit: BigNumber
          try {
            if (isNative) {
              gasLimit = await v2RouterContract.estimateGas.swapExactETHForTokens(
                amountOutMin,
                path,
                to,
                deadline,
                {
                  value: amountIn,
                }
              );
            } else if (isNativeOut) {
              gasLimit = await v2RouterContract.estimateGas.swapExactTokensForETH(
                amountIn,
                amountOutMin,
                path,
                to,
                deadline
              );

            } else {
              gasLimit = await v2RouterContract.estimateGas.swapExactTokensForTokens(
                amountIn,
                amountOutMin,
                path,
                to,
                deadline
              );
            }
            trace.setData('gasLimit', gasLimit.toNumber())
          } catch (gasError) {
            sendAnalyticsEvent(SwapEventName.SWAP_ESTIMATE_GAS_CALL_FAILED, {
              ...formatCommonPropertiesForTrade(trade, options.slippageTolerance),
              ...analyticsContext,
              client_block_number: blockNumber,
              tx,
              isAutoSlippage,
            })
            console.warn(gasError)
            throw new GasEstimationError()
          }

          const response = await trace.child(
            { name: 'Send transaction', op: 'wallet.send_transaction' },
            async (walletTrace) => {
              try {
                if (isNative) {
                  return await provider.getSigner().sendTransaction({ ...tx, gasLimit, value: amountIn })

                } else {
                  return await provider.getSigner().sendTransaction({ ...tx, gasLimit })

                }
              } catch (error) {
                if (didUserReject(error)) {
                  walletTrace.setStatus('cancelled')
                  throw new UserRejectedRequestError(swapErrorToUserReadableMessage(error))
                } else {
                  throw error
                }
              }
            }
          )
          if (tx.data !== response.data) {
            sendAnalyticsEvent(SwapEventName.SWAP_MODIFIED_IN_WALLET, {
              txHash: response.hash,
              ...analyticsContext,
            })
            if (!response.data || response.data.length === 0 || response.data === '0x') {
              throw new ModifiedSwapError()
            }
          }
          return { type: TradeFillType.Classic as const, response, deadline }
        } catch (error: unknown) {
          if (error instanceof GasEstimationError) {
            throw error
          } else if (error instanceof UserRejectedRequestError) {
            trace.setStatus('cancelled')
            throw error
          } else if (error instanceof ModifiedSwapError) {
            trace.setError(error, 'data_loss')
            throw error
          } else {
            trace.setError(error)
            throw Error(swapErrorToUserReadableMessage(error))
          }
        }
      }),
    [
      account,
      chainId,
      provider,
      trade,
      getDeadline,
      options.slippageTolerance,
      options.permit,
      options.feeOptions,
      options.flatFeeOptions,
      fiatValues,
      portfolioBalanceUsd,
      analyticsContext,
      connector,
      blockNumber,
      isAutoSlippage,
      v2RouterContract
    ]
  )
}
