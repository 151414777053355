import { useWeb3React } from "@web3-react/core"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useContract, useFTSORegistryContract, useV2RouterContract } from "./useContract"
import { useSingleContractMultipleData } from "lib/hooks/multicall"
import { Interface, formatEther } from "ethers/lib/utils"
import FTSO_Registry_ABI from 'constants/abis/ftsoRegistry.json'
import { isFlareChain, isSupportedChain } from "constants/chains"
import { AddressMap, ChainId, FTSORegistry, WETH9 } from "@uniswap/sdk-core"
import { ethers } from "ethers"
import { OrderDirection } from "graphql/data/util"
import { PricesSortFields } from "components/Ftsos/PricesTable"

const OSTokenSyms: Record<string, string> = {
    "ORACLE": "0xE0a4C961Be68a5C9c65cAEA12dC57dfCacee8F7b",
    "VIS": "0xB36452dABec46872fA7e1b2f2E3657F86b91f7a5",
    "PRO": "0x8301fb78ccc7F6603eee3431f3111b5E6eEB977c"
}

export type PriceTableSortState = {
    sortBy: PricesSortFields
    sortDirection: OrderDirection
}

export function sortSymbols(symbols: string[], prices: Record<string, number>, sortState?: PriceTableSortState) {
    if (!sortState) return symbols
    return symbols.slice().sort((a, b) => {
        const aInfo = prices[a]
        const bInfo = prices[b]
        if (!aInfo || !bInfo) return 1;
        switch (sortState.sortBy) {
            case PricesSortFields.Symbol:
                return sortState.sortDirection === OrderDirection.Desc ? b.localeCompare(a) : a.localeCompare(b)
            case PricesSortFields.Price:
                return sortState.sortDirection === OrderDirection.Desc ? bInfo - aInfo : aInfo - bInfo
            default:
                return sortState.sortDirection === OrderDirection.Desc ? bInfo - aInfo : aInfo - bInfo
        }
    })
}

export const useNativeTokenPrice = (sortState?: PriceTableSortState) => {
    const { chainId } = useWeb3React()
    const [tokenPrices, setTokenPrices] = useState<Record<string, number>>({})
    const [nativePrice, setNativePrice] = useState(0);
    const [supportedSymbols, setSupportedSymbols] = useState<string[]>([])
    const [loading, setLoading] = useState(false)

    const ftsoRegistry = useContract(FTSORegistry[chainId ?? ChainId.COSTON2], FTSO_Registry_ABI, true)

    useEffect(() => {
        if (!isFlareChain(chainId) || !ftsoRegistry) return

        const func = async () => {
            setLoading(true)
            try {
                const symbolsRes = await ftsoRegistry.getSupportedSymbols()
                const pricesRes = await ftsoRegistry.getCurrentPricesBySymbols(symbolsRes)
                let prices = {}
                let natPrice = 0;
                pricesRes.map((item: any, ind: number) => {
                    const decimal = item.decimals.toNumber()
                    const price = item.price.toNumber()
                    const symbol = symbolsRes[ind]
                    const usdPrice = price / 10 ** decimal
                    if (symbol && usdPrice)
                        prices = { ...prices, [symbol]: usdPrice }
                    if (symbol === "C2FLR" || symbol === "FLR" || symbol === "SGB")
                        natPrice = usdPrice
                })
                setSupportedSymbols(symbolsRes)
                setTokenPrices(prices)
                setNativePrice(natPrice)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)

        }
        func()
        const intervalFunc = setInterval(func, 30000);

        return () => clearInterval(intervalFunc);
    }, [ftsoRegistry, chainId])

    const sortedSymbols = useMemo(() => sortSymbols(supportedSymbols, tokenPrices, sortState), [supportedSymbols, tokenPrices, sortState])

    return {
        supportedSymbols: sortedSymbols,
        tokenPrices,
        nativePrice,
        loading
    }
}

export const useTokensPrice = () => {
    const { chainId } = useWeb3React()
    const [tokenPrices, setTokenPrices] = useState<Record<string, number>>({})

    const symbols = ["ORACLE", "VIS", "PRO"]

    const routerContract = useV2RouterContract()
    const amountIn = ethers.utils.parseEther('1');
    const args = useMemo(() => isSupportedChain(chainId) ?
        symbols.map((sym: string) => [amountIn, [WETH9[chainId].address, OSTokenSyms[sym]]]) : [], [chainId])

    const swapRateRes = useSingleContractMultipleData(routerContract, 'getAmountsOut', args)

    useEffect(() => {
        if (!routerContract || !chainId || args.length < 1) return
        if (!swapRateRes || swapRateRes.length < 1) return
        if (!swapRateRes[0].result) return
        let prices = {}
        symbols.forEach((sym, ind) => {
            const res = swapRateRes[ind].result?.amounts
            const rate = parseFloat(formatEther(res ? res[1] : "0x0"))
            if (rate > 0)
                prices = { ...prices, [sym]: 1 / rate }
        })
        setTokenPrices(prices)
    }, [swapRateRes, args, chainId, routerContract])

    return {
        tokenPrices,
        symbols
    }
} 