import { ChainId } from '@uniswap/sdk-core'
import useHttpLocations from 'hooks/useHttpLocations'
import { useMemo } from 'react'
import { isAddress } from 'utilities/src/addresses'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
import AvaxLogo from '../../assets/svg/avax_logo.svg'
import BnbLogo from '../../assets/svg/bnb-logo.svg'
import coston2Logo from '../../assets/svg/flare.svg'
import CeloLogo from '../../assets/svg/celo_logo.svg'
import MaticLogo from '../../assets/svg/matic-token-icon.svg'
import { NATIVE_CHAIN_ID, nativeOnChain } from '../../constants/tokens'

type Network =
  | 'coston2flare'

export function chainIdToNetworkName(networkId: ChainId): Network {
  switch (networkId) {
    case ChainId.COSTON2:
      return 'coston2flare'
    default:
      return 'coston2flare'
  }
}

export function getNativeLogoURI(chainId: ChainId = ChainId.COSTON2): string {
  switch (chainId) {
    default:
      return coston2Logo
  }
}

function getTokenLogoURI(address: string, chainId: ChainId = ChainId.COSTON2): string | void {
  const networkName = chainIdToNetworkName(chainId)
  const networksWithUrls = [
    ChainId.COSTON2,
  ]
  if (chainId === ChainId.COSTON2 && address === nativeOnChain(chainId).wrapped.address)
    return coston2Logo

  if (networksWithUrls.includes(chainId)) {
    return `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${networkName}/assets/${address}/logo.png`
  }
}

export default function useCurrencyLogoURIs(
  currency:
    | {
      isNative?: boolean
      isToken?: boolean
      address?: string
      chainId: number
      logoURI?: string | null
    }
    | null
    | undefined
): string[] {
  const locations = useHttpLocations(currency?.logoURI)
  return useMemo(() => {
    const logoURIs = [...locations]
    if (currency) {
      if (currency.isNative || currency.address === NATIVE_CHAIN_ID) {
        logoURIs.push(getNativeLogoURI(currency.chainId))
      } else if (currency.isToken || currency.address) {
        const checksummedAddress = isAddress(currency.address)
        const logoURI = checksummedAddress && getTokenLogoURI(checksummedAddress, currency.chainId)
        if (logoURI) {
          logoURIs.push(logoURI)
        }
      }
    }
    return logoURIs
  }, [currency, locations])
}
