import { ChainId, Token } from '@uniswap/sdk-core'
import { PortfolioLogo } from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo'
import { SearchToken } from 'graphql/data/SearchTokens'
import { TokenQueryData } from 'graphql/data/Token'
import { TopToken } from 'graphql/data/TopTokens'
import { gqlToCurrency, supportedChainIdFromGQLChain } from 'graphql/data/util'
import { useMemo } from 'react'

import { AssetLogoBaseProps } from './AssetLogo'
import { nativeOnChain } from 'constants/tokens'
import { useDefaultActiveTokens } from 'hooks/Tokens'

export default function QueryTokenLogo(
  props: AssetLogoBaseProps & {
    token?: Token
  }
) {
  const token = props.token
  const chainId = token?.chainId ?? ChainId.COSTON2
  const activeTokensList = useDefaultActiveTokens(chainId)
  const currency = token?.isNative ? nativeOnChain(chainId) : token ? token : undefined
  const logoUrl = undefined


  return (
    <PortfolioLogo currencies={useMemo(() => [currency], [currency])} chainId={chainId} images={[logoUrl]} {...props} />
  )
}
