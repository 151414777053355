import { ChainId } from '@uniswap/sdk-core';
import { useWeb3React } from '@web3-react/core';
import { useNativeTokenPrice, useTokensPrice } from 'hooks/useNativeTokenPrice';
import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

const flrImageURL = "https://raw.githubusercontent.com/oracle-swap/default-token-list/main/assets/token/114/flare.svg"
const sgbImageURL = ""
const visImageURL = "https://raw.githubusercontent.com/oracle-swap/default-token-list/main/assets/token/114/vis.svg"
const oracleImageURL = "https://raw.githubusercontent.com/oracle-swap/default-token-list/main/assets/token/114/oracle.svg"
const proImageURL = "https://raw.githubusercontent.com/oracle-swap/default-token-list/main/assets/token/114/pro.svg"

// Define the styled components



const scroll = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;


const Container = styled.div`
  font-family: Arial, sans-serif;
  color: ${({ theme }) => theme.darkMode ? theme.neutral3 : "#000"};
  overflow: hidden;
  width: 100%;
`;

const Marquee = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  white-space: nowrap;
  animation: ${(props) =>
    css`
      ${scroll} 30s linear infinite
    `};
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0px 0px;
  padding: 0px 0px;
  font-size: 15px;
  color: goldenrod;
  font-weight: bold;

`;

const ItemIcon = styled.span`
  margin-right: 10px;
  img {
    height: 24px;
  }
`;

const Currency = styled.span`
  color: goldenrod;
`;

// Define the main component
const TokenPriceForm = () => {
  const { chainId } = useWeb3React()
  const { nativePrice } = useNativeTokenPrice()
  const { tokenPrices } = useTokensPrice()

  return (
    <Container>
      <Marquee>
        <ListItem>
          <ItemIcon>
            <img src={flrImageURL} alt="FLR" />
          </ItemIcon>
          1 {chainId === ChainId.SONGBIRD ? "SGB" : "FLR"} = <Currency>${nativePrice.toFixed(4)} USD</Currency>
        </ListItem>
        {/* <ListItem>
          <ItemIcon>
            <img src={sgbImageURL} alt="SGB" />
          </ItemIcon>
          1 SGB = <Currency>$0.01 USD</Currency>
        </ListItem> */}
        <ListItem>
          <ItemIcon>
            <img src={oracleImageURL} alt="ORACLE" />
          </ItemIcon>
          ($ {tokenPrices["ORACLE"] ? (tokenPrices["ORACLE"] * nativePrice).toFixed(4) : 0}) 1 ORACLE = <Currency>{tokenPrices["ORACLE"]?.toFixed(4) ?? 0} FLR</Currency>
        </ListItem>
        <ListItem>
          <ItemIcon>
            <img src={proImageURL} alt="PRO" />
          </ItemIcon>
          ($ {tokenPrices["PRO"] ? (tokenPrices["PRO"] * nativePrice).toFixed(4) : 0})  1 PRO = <Currency>{tokenPrices["PRO"]?.toFixed(4) ?? 0} FLR</Currency>
        </ListItem>
        <ListItem>
          <ItemIcon>
            <img src={visImageURL} alt="VIS" />
          </ItemIcon>
          ($ {tokenPrices["VIS"] ? (tokenPrices["VIS"] * nativePrice).toFixed(4) : 0}) 1 VIS = <Currency>{tokenPrices["VIS"]?.toFixed(4) ?? 0} FLR</Currency>
        </ListItem>
      </Marquee >
    </Container>
  );
};

export default TokenPriceForm;
