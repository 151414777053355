import { BrowserEvent, InterfaceElementName, SharedEventName } from '@uniswap/analytics-events'
import { Position } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { TraceEvent } from 'analytics'
import Row from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { BIPS_BASE } from 'constants/misc'
import { useFilterPossiblyMaliciousPositions } from 'hooks/useFilterPossiblyMaliciousPositions'
import { useSwitchChain } from 'hooks/useSwitchChain'
import { t } from 'i18n'
import { EmptyWalletModule } from 'nft/components/profile/view/EmptyWalletContent'
import { useCallback, useMemo, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'
import { NumberType, useFormatter } from 'utils/formatNumbers'

import { ExpandoRow } from '../ExpandoRow'
import { useToggleAccountDrawer } from '../hooks'
import { PortfolioLogo } from '../PortfolioLogo'
import PortfolioRow, { PortfolioSkeleton, PortfolioTabWrapper } from '../PortfolioRow'
import { PositionInfo } from './cache'
import { useFeeValues } from './hooks'
import useMultiChainPositions from './useMultiChainPositions'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { useTokenBalancesWithLoadingIndicator } from 'lib/hooks/useCurrencyBalance'
import { useV2Pairs } from 'hooks/useV2Pairs'
import { Pair } from  "@uniswap/v2-sdk"
import { usePoolBalances } from 'hooks/useTokenBalances'

/**
 * Takes an array of PositionInfo objects (format used by the Uniswap Labs gql API).
 * The hook access PositionInfo.details (format used by the NFT position contract),
 * filters the PositionDetails data for malicious content,
 * and then returns the original data in its original format.
 */
function useFilterPossiblyMaliciousPositionInfo(positions: PositionInfo[] | undefined): PositionInfo[] {
  const tokenIdsToPositionInfo: Record<string, PositionInfo> = useMemo(
    () =>
      positions
        ? positions.reduce((acc, position) => ({ ...acc, [position.details.tokenId.toString()]: position }), {})
        : {},
    [positions]
  )
  const positionDetails = useMemo(() => positions?.map((position) => position.details) ?? [], [positions])
  const filteredPositionDetails = useFilterPossiblyMaliciousPositions(positionDetails)

  return useMemo(
    () => filteredPositionDetails.map((positionDetails) => tokenIdsToPositionInfo[positionDetails.tokenId.toString()]),
    [filteredPositionDetails, tokenIdsToPositionInfo]
  )
}

export default function Pools({ account }: { account: string }) {
  // const { positions, loading } = useMultiChainPositions(account)
  // const filteredPositions = useFilterPossiblyMaliciousPositionInfo(positions)
  // const [showClosed, toggleShowClosed] = useReducer((showClosed) => !showClosed, false)

  // const [openPositions, closedPositions] = useMemo(() => {
  //   const openPositions: PositionInfo[] = []
  //   const closedPositions: PositionInfo[] = []
  //   for (let i = 0; i < filteredPositions.length; i++) {
  //     const position = filteredPositions[i]
  //     if (position.closed) {
  //       closedPositions.push(position)
  //     } else {
  //       openPositions.push(position)
  //     }
  //   }
  //   return [openPositions, closedPositions]
  // }, [filteredPositions])
  let trackedTokenPairs = useTrackedTokenPairs()

  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = useV2Pairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const tokenBalances = usePoolBalances(account, liquidityTokensWithBalances.map((pair) => pair.liquidityToken.address))

  console.log("v2Pairs: ", liquidityTokensWithBalances, v2Pairs, allV2PairsWithLiquidity)
  const toggleWalletDrawer = useToggleAccountDrawer()

  const positionsInfo = useMemo(
    () =>
      allV2PairsWithLiquidity.map(
        (pair, index) => {
          const poolAddress = liquidityTokensWithBalances[index].liquidityToken.address
          return {
            pair,
            amount: tokenBalances[poolAddress] ?? 0
          }
        }
      ), [tokenBalances, allV2PairsWithLiquidity]
  )

  // if (!filteredPositions || loading) {
  //   return <PortfolioSkeleton />
  // }
  if (allV2PairsWithLiquidity.length === 0) {
    return <EmptyWalletModule type="pool" onNavigateClick={toggleWalletDrawer} />
  }

  return (
    <PortfolioTabWrapper>
      {positionsInfo.map((positionInfo, index) => (
        <PositionListItem
          key={index.toString() + positionInfo.pair?.chainId}
          positionInfo={positionInfo}
        />
      ))}
      {/* <ExpandoRow
        title={t`Closed Positions`}
        isExpanded={showClosed}
        toggle={toggleShowClosed}
        numItems={closedPositions.length}
      >
        {closedPositions.map((positionInfo) => (
          <PositionListItem
            key={positionInfo.details.tokenId.toString() + positionInfo.chainId}
            positionInfo={positionInfo}
          />
        ))}
      </ExpandoRow> */}
    </PortfolioTabWrapper>
  )
}

const ActiveDot = styled.span<{ closed: boolean; outOfRange: boolean }>`
  background-color: ${({ theme, closed, outOfRange }) =>
    closed ? theme.neutral2 : outOfRange ? theme.deprecated_accentWarning : theme.success};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-left: 4px;
  margin-top: 1px;
`

function calculateLiquidityValue(price0: number | undefined, price1: number | undefined, position: Position) {
  if (!price0 || !price1) return undefined

  const value0 = parseFloat(position.amount0.toExact()) * price0
  const value1 = parseFloat(position.amount1.toExact()) * price1
  return value0 + value1
}

function PositionListItem({ positionInfo }: {
  positionInfo: {
    pair: Pair;
    amount: number;
  }
}) {
  const { formatNumber } = useFormatter()

  const { pair: { liquidityToken: pool, chainId, token0, token1 }, amount } = positionInfo
  // const { chainId, pool, liquidityValue, token0, token1 } = positionInfo

  // const { priceA, priceB, fees: feeValue } = useFeeValues(positionInfo)
  // const liquidityValue = calculateLiquidityValue(priceA, priceB, position)

  const navigate = useNavigate()
  const toggleWalletDrawer = useToggleAccountDrawer()
  const { chainId: walletChainId, connector } = useWeb3React()
  const switchChain = useSwitchChain()
  const onClick = useCallback(async () => {
    if (walletChainId !== pool.chainId) await switchChain(connector, pool.chainId)
    toggleWalletDrawer()
    navigate('/pool/' + pool.address)
  }, [walletChainId, switchChain, connector, toggleWalletDrawer, navigate])
  const analyticsEventProperties = useMemo(
    () => ({
      chain_id: chainId,
      pool_token_0_symbol: token0.symbol,
      pool_token_1_symbol: token1.symbol,
      pool_token_0_address: token0.address,
      pool_token_1_address: token1.address,
    }),
    [chainId, token0.address, token0.symbol, token1.address, token1.symbol]
  )

  return (
    <TraceEvent
      events={[BrowserEvent.onClick]}
      name={SharedEventName.ELEMENT_CLICKED}
      element={InterfaceElementName.MINI_PORTFOLIO_POOLS_ROW}
      properties={analyticsEventProperties}
    >
      <PortfolioRow
        onClick={onClick}
        left={<PortfolioLogo chainId={chainId} currencies={[token0, token1]} />}
        title={
          <Row>
            <ThemedText.SubHeader>
              {token0.symbol} / {token1?.symbol}
            </ThemedText.SubHeader>
          </Row>
        }
        right={
          <>
            <MouseoverTooltip
              placement="left"
              text={
                <div style={{ padding: '4px 0px' }}>
                  <ThemedText.BodySmall>{`${formatNumber({
                    input: amount??0,
                    type: NumberType.PortfolioBalance,
                  })} (liquidity)`}</ThemedText.BodySmall>
                </div>
              }
            >
              <ThemedText.SubHeader>
                {formatNumber({
                  input: (amount ?? 0),
                  type: NumberType.PortfolioBalance,
                })}
              </ThemedText.SubHeader>
            </MouseoverTooltip>

            {/* <Row justify="flex-end">
              <ThemedText.BodySmall color="neutral2">
                {closed ? t`Closed` : inRange ? t`In range` : t`Out of range`}
              </ThemedText.BodySmall>
              <ActiveDot closed={closed} outOfRange={!inRange} />
            </Row> */}
          </>
        }
      />
    </TraceEvent>
  )
}
