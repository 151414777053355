import { ComponentProps } from 'react'

export function AppleLogo(props: ComponentProps<'svg'>) {
  return (
    <svg
      width={props.width || "20px"}  // Default width if not provided
      height={props.height || "20px"} // Default height if not provided
      viewBox="0 0 36.7 36.6"
      fill={props.fill || "#E62058"} // Use passed fill color or default to #E62058
      xmlns="http://www.w3.org/2000/svg"
      style={props.style} // Allow style to be passed in
    >
      <style>
        {`.st0 { fill: ${props.fill || '#E62058'}; }`}  // Dynamic fill color for .st0 class
      </style>
      <path className="st0" d="M27.3,13.7H9.2c-5,0-9,3.9-9.2,8.9c0,0.1,0.1,0.2,0.2,0.2h18.1c5,0,9-3.9,9.2-8.9 C27.5,13.8,27.4,13.7,27.3,13.7L27.3,13.7L27.3,13.7z"/>
      <path className="st0" d="M36.4,0H9.2c-5,0-9,3.9-9.2,8.9C0,9,0.1,9.2,0.2,9.2h27.3c5,0,9-3.9,9.2-8.9 C36.7,0.1,36.6,0,36.4,0L36.4,0L36.4,0z"/>
      <circle className="st0" cx="4.6" cy="32" r="4.6"/>
    </svg>
  )
}
