import { ColumnCenter } from 'components/Column';
import { useCurrency } from 'hooks/Tokens';
import { Trans } from 'i18n';
import { Swap } from 'pages/Swap';
import { useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import styled, { css, keyframes } from 'styled-components';

import { BREAKPOINTS } from 'theme';
import { Text } from 'ui/src/components/text/Text';
import { heightBreakpoints } from 'ui/src/theme';
import { Box, H1 } from '../components/Generics';
import { TokenCloud } from '../components/TokenCloud/index';
import { Hover, RiseIn, RiseInText } from '../components/animations';
import { Navigate, useNavigate } from 'react-router-dom';

const Container = styled(Box)`
  min-width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden; /* This prevents any overflow from the children */
  padding-top: 72px;
`;


const SpiralBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5); // Scale adjusted to fill the visual field appropriately
  width: 200vh;
  height: 200vh;
  background: 
    radial-gradient(circle, #e5b430 0%, transparent 40%), // Adjust the transparent radius for the size of the gradient
    url('https://assets-global.website-files.com/64c6c2033efd28e378d0a9a1/64c6f30a98cf03b54d74be8b_fractal%20line%201Asset%201.svg') no-repeat center center;
  background-size: 100% 100%, contain; // Set initial gradient size to fill the area
  z-index: 0;
  animation: rotate 120s linear infinite, pulse 10s ease-in-out infinite;

  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(-360deg); // Counter-clockwise rotation
    }
  }

  @keyframes pulse {
    0%, 100% {
      background-size: 100% 100%, contain; // Start and end at the initial size
    }
    50% {
      background-size: 120% 120%, contain; // Expand to 120% at the midpoint
    }
  }
`;





const LandingSwapContainer = styled(Box)`
  width: 480px;
  padding: 8px;
  border-radius: 24px;
  background: ${({ theme }) => theme.surface1};
`;

const LandingSwap = styled(Swap)`
  position: relative;
  width: 100%;

  & > div:first-child {
    padding: 0px;
  }
  & > div:first-child > div:first-child {
    display: none;
  }
`;

const StyledH1 = styled(H1)`
  @media (max-width: 768px) {
    font-size: 52px;
  }
  @media (max-width: 464px) {
    font-size: 36px;
  }
  @media (max-height: 668px) {
    font-size: 28px;
  }
`;

const shrinkAndFade = keyframes`
  /* ... (unchanged) */
`;

const Center = styled(Box) <{ transition?: boolean }>`
  width: unset;
  pointer-events: none;
  padding: 48px 0px;
  @media (max-width: 464px), (max-height: 700px) {
    padding-top: 24px;
  }
  @media (max-width: 464px), (max-height: 668px) {
    padding-top: 8px;
  }
  gap: 24px;
  @media (max-height: 800px) {
    gap: 16px;
  }
  ${({ transition }) =>
    transition &&
    css`
      animation: ${shrinkAndFade} 1s ease-in-out forwards;
    `}
`;

const LearnMoreContainer = styled(Box)`
  bottom: 48px;
  @media (max-width: ${BREAKPOINTS.md}px) {
    bottom: 64px;
  }

  @media (max-height: ${heightBreakpoints.short}px) {
    display: none;
  }
`

interface HeroProps {
  scrollToRef: () => void;
  transition?: boolean; // Optional transition prop (fixed the error)
}

export function Hero({ scrollToRef, transition }: HeroProps) {
  const [scrollPosition, setScrollPosition] = useState(0);

  const navigate = useNavigate()

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };



  const initialInputCurrency = useCurrency('ETH');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const translateY = -scrollPosition / 7;
  const opacityY = 1 - scrollPosition / 1000;

  const Body1 = styled.p`
  padding: 0;
  margin: 0;
  color: ${(props) => props.color || props.theme.neutral1};
  font-feature-settings: 'ss07' on;
  /* Body/1 */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.01em;
`

  return (
    <Container
      position="relative"
      height="100vh"
      justify="center"
      style={{ transform: `translate(0px, ${translateY}px)`, opacity: opacityY }}
    >
      <SpiralBackground />

      <TokenCloud transition={transition} />
      <Center
        direction="column"
        align="center"
        maxWidth="85vw"
        transition={transition}
        style={{ transform: `translate(0px, ${translateY}px)`, opacity: opacityY }}
      >
        <Box maxWidth="920px" direction="column" align="center">
          <StyledH1>
            <RiseInText delay={0.0}>
              <Trans>Swap,</Trans>
            </RiseInText>{' '}
            <RiseInText delay={0.1}>
              <Trans>Earn,</Trans>
            </RiseInText>
          </StyledH1>
          <RiseIn delay={0.2}>
            <StyledH1>
              <Trans>Delegate,</Trans>
            </StyledH1>
            <StyledH1>
              <Trans>Vote.</Trans>
            </StyledH1>
          </RiseIn>
        </Box>

        <RiseIn delay={0.4}>
          <LandingSwapContainer onClick={() => navigate("/swap")}>
            <LandingSwap syncTabToUrl={false} initialInputCurrency={initialInputCurrency} />
          </LandingSwapContainer>
        </RiseIn>

        <RiseIn delay={0.3}>
          <Text
            variant="body1"
            textAlign="center"
            maxWidth={430}
            color="$neutral1"
            $short={{
              variant: 'body2',
            }}
          >
            <Body1>
              <Trans>
              ♎ OracleSwap, the next generation of DeFi powered by the 🔥 Flare Network. Regenerative, Modular, Multi-Earning LP Tokens that earn native FLR and SuperFarm rewards simultaneously to help mitigate impermanent loss to providers.
              </Trans>
            </Body1>
          </Text>
        </RiseIn>
      </Center>
      <LearnMoreContainer
        position="absolute"
        width="100%"
        align="center"
        justify="center"
        pointerEvents="none"
        style={{ transform: `translate(0px, ${translateY}px)`, opacity: opacityY }}
      >
        <RiseIn delay={0.3}>
          <Box
            direction="column"
            align="center"
            justify="flex-start"
            onClick={() => scrollToRef()}
            style={{ cursor: 'pointer' }}
            width="500px"
          >
            <Hover>
              <ColumnCenter>
                <Text variant="body2">
                  <Trans>Scroll to learn more</Trans>
                </Text>
                <ChevronDown />
              </ColumnCenter>
            </Hover>
          </Box>
        </RiseIn>
      </LearnMoreContainer>
    </Container>
  );
}
