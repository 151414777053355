import { ChainId } from "@uniswap/sdk-core"
import { useDefaultActiveTokens } from "./Tokens"
import { useMemo } from "react"


export const useTokens = (chainId: ChainId | number) => {
    const activeTokensList = useDefaultActiveTokens(chainId)
    return useMemo(()=> {
        return Object.values(activeTokensList)
    }, [activeTokensList])
}