import { ApolloError } from '@apollo/client'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { ChainId, Percent, Token } from '@uniswap/sdk-core'
import { PortfolioLogo } from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo'
import Row from 'components/Row'
import { Table } from 'components/Table'
import { Cell } from 'components/Table/Cell'
import { ClickableHeaderRow, HeaderArrow, HeaderSortText, StyledExternalLink } from 'components/Table/styled'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { exploreSearchStringAtom } from 'components/Tokens/state'
import { NameText } from 'components/Tokens/TokenTable'
import { MouseoverTooltip } from 'components/Tooltip'
import {
    OrderDirection,
    validateUrlChainParam,
} from 'graphql/data/util'
import { useNativeTokenPrice } from 'hooks/useNativeTokenPrice'
import { IFtsoInfo, IIFtsoInfo, ProviderProps, useMetrics } from 'hooks/useProvider'
import { getChainIdFromName } from 'hooks/useSyncChainQuery'
import { Trans } from 'i18n'
import { useAtom } from 'jotai'
import { atomWithReset, useAtomValue, useResetAtom, useUpdateAtom } from 'jotai/utils'
import { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'
import { shortenAddress } from 'utilities/src/addresses'
import { useFormatter } from 'utils/formatNumbers'
import { getExplorerLink, getFtsoExplorerLink } from 'utils/getExplorerLink'

export interface TableFTSOs {
    name: string
    address: string
    websiteLinkk: string
    rate: number
    votepower: number
    fee: number
}

export enum PricesSortFields {
    Symbol = 'Symbol',
    Price = "Price",
}

const HEADER_DESCRIPTIONS: Record<PricesSortFields, ReactNode | undefined> = {
    [PricesSortFields.Symbol]: undefined,
    [PricesSortFields.Price]: undefined,
}

const TableWrapper = styled.div`
  margin: 0 auto;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
`

const Badge = styled(ThemedText.LabelMicro)`
  padding: 2px 6px;
  background: ${({ theme }) => theme.surface2};
  border-radius: 5px;
`

interface PricesTableValues {
    index: number
    pricesDescription: ReactElement
    symbol: string
    price: number
}

export enum PricesTableColumns {
    Index,
    PricesDescription,
    Symbol,
    Price
}

function PricesDescription({
    imageURL,
    symbol,
    chainId,
}: {
    imageURL: string
    symbol: string
    chainId: ChainId
}) {
    return (
        <Row gap="20px">
            <PortfolioLogo chainId={chainId} images={[imageURL]} />
            <NameText>
                {symbol}
            </NameText>
        </Row>
    )
}

export const sortMethodAtom = atomWithReset<PricesSortFields>(PricesSortFields.Symbol)
export const sortAscendingAtom = atomWithReset<boolean>(false)

function useSetSortMethod(newSortMethod: PricesSortFields) {
    const [sortMethod, setSortMethod] = useAtom(sortMethodAtom)
    const setSortAscending = useUpdateAtom(sortAscendingAtom)

    return useCallback(() => {
        if (sortMethod === newSortMethod) {
            setSortAscending((sortAscending) => !sortAscending)
        } else {
            setSortMethod(newSortMethod)
            setSortAscending(false)
        }
    }, [sortMethod, setSortMethod, setSortAscending, newSortMethod])
}

const HEADER_TEXT: Record<PricesSortFields, ReactNode> = {
    [PricesSortFields.Symbol]: <Trans>Symbol</Trans>,
    [PricesSortFields.Price]: <Trans>Price</Trans>,
}

function PricesTableHeader({
    category,
    isCurrentSortMethod,
    direction,
}: {
    category: PricesSortFields
    isCurrentSortMethod: boolean
    direction: OrderDirection
}) {
    const handleSortCategory = useSetSortMethod(category)
    return (
        <MouseoverTooltip disabled={!HEADER_DESCRIPTIONS[category]} text={HEADER_DESCRIPTIONS[category]} placement="top">
            <ClickableHeaderRow $justify="flex-end" onClick={handleSortCategory}>
                {isCurrentSortMethod && <HeaderArrow direction={direction} />}
                <HeaderSortText $active={isCurrentSortMethod}>{HEADER_TEXT[category]}</HeaderSortText>
            </ClickableHeaderRow>
        </MouseoverTooltip>
    )
}

export function TopPricesTable() {
    const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)
    const chainId = getChainIdFromName(chainName)
    const sortMethod = useAtomValue(sortMethodAtom)
    const sortAscending = useAtomValue(sortAscendingAtom)
    const {supportedSymbols, tokenPrices, loading} = useNativeTokenPrice(
        { sortBy: sortMethod, sortDirection: sortAscending ? OrderDirection.Asc : OrderDirection.Desc }
    )

    const resetSortMethod = useResetAtom(sortMethodAtom)
    const resetSortAscending = useResetAtom(sortAscendingAtom)
    useEffect(() => {
        resetSortMethod()
        resetSortAscending()
    }, [resetSortAscending, resetSortMethod])

    const allDataStillLoading = loading && !supportedSymbols.length

    return (
        <TableWrapper data-testid="top-prices-explore-table">
            <PricesTable
                symbols={[...supportedSymbols]}
                loading={allDataStillLoading}
                chainId={chainId}
                prices={tokenPrices}
                maxWidth={1200}
            />
        </TableWrapper>
    )
}

export function PricesTable({
    symbols,
    loading,
    error,
    loadMore,
    chainId,
    maxWidth,
    maxHeight,
    hiddenColumns,
    prices
}: {
    symbols?: string[]
    prices: Record<string, number>
    loading: boolean
    error?: ApolloError
    loadMore?: ({ onComplete }: { onComplete?: () => void }) => void
    chainId: ChainId
    maxWidth?: number
    maxHeight?: number
    hiddenColumns?: PricesTableColumns[]
}) {
    const { formatNumber, formatPercent } = useFormatter()
    const sortAscending = useAtomValue(sortAscendingAtom)
    const orderDirection = sortAscending ? OrderDirection.Asc : OrderDirection.Desc
    const sortMethod = useAtomValue(sortMethodAtom)
    const filterString = useAtomValue(exploreSearchStringAtom)
    const priceTableValues: PricesTableValues[] | undefined = useMemo(
        () => {
            return symbols?.map((symbol, index) => {
                const sortRank = index + 1
                const logoURI = `https://cdn.flaremetrics.io/crypto-emblems/${symbol.toLowerCase().replace(/^(test|c2)/, '')}_64x64.png`

                return {
                    index: sortRank,
                    pricesDescription: (
                        <PricesDescription imageURL={logoURI} symbol={symbol} chainId={chainId} />
                    ),
                    symbol: symbol,
                    price: prices[symbol],
                }
            }) ?? []
        },
        [chainId, symbols, filterString, prices]
    )

    const showLoadingSkeleton = loading || !!error
    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<PricesTableValues>()
        return [
            !hiddenColumns?.includes(PricesTableColumns.Index)
                ? columnHelper.accessor((row) => row.index, {
                    id: 'index',
                    header: () => (
                        <Cell justifyContent="center" width={44}>
                            <ThemedText.BodySecondary>#</ThemedText.BodySecondary>
                        </Cell>
                    ),
                    cell: (index) => (
                        <Cell justifyContent="center" loading={showLoadingSkeleton} width={44}>
                            <ThemedText.BodySecondary>{index.getValue?.()}</ThemedText.BodySecondary>
                        </Cell>
                    ),
                })
                : null,
            !hiddenColumns?.includes(PricesTableColumns.PricesDescription)
                ? columnHelper.accessor((row) => {
                    return row.pricesDescription
                }, {
                    id: 'priceDescription',
                    header: () => (
                        <Cell justifyContent="flex-start" width={100} grow>
                            <PricesTableHeader
                                category={PricesSortFields.Symbol}
                                isCurrentSortMethod={sortMethod === PricesSortFields.Symbol}
                                direction={orderDirection}
                            />
                        </Cell>
                    ),
                    cell: (ftsosDescription) => (
                        <Cell justifyContent="flex-start" loading={showLoadingSkeleton} width={100} grow>
                            {ftsosDescription.getValue?.()}
                        </Cell>
                    ),
                })
                : null,
            !hiddenColumns?.includes(PricesTableColumns.Price)
                ? columnHelper.accessor((row) => row.price, {
                    id: 'price',
                    header: () => (
                        <Cell justifyContent="flex-start" width={100} grow>
                            <PricesTableHeader
                                category={PricesSortFields.Price}
                                isCurrentSortMethod={sortMethod === PricesSortFields.Price}
                                direction={orderDirection}
                            />
                        </Cell>
                    ),
                    cell: (price) => (
                        <Cell justifyContent="flex-start" loading={showLoadingSkeleton} width={100} grow>
                                {price.getValue?.()}
                        </Cell>
                    ),
                })
                : null,
            // Filter out null values
        ].filter(Boolean) as ColumnDef<PricesTableValues, any>[]
    }, [formatNumber, formatPercent, hiddenColumns, orderDirection, showLoadingSkeleton, sortMethod])

    return (
        <Table
            columns={columns}
            data={priceTableValues}
            loading={loading}
            error={error}
            loadMore={loadMore}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
        />
    )
}
