import { useScreenSize } from 'hooks/useScreenSize';
import { Trans } from 'i18n';
import { Link } from 'react-router-dom';
import { useTogglePrivacyPolicy } from 'state/application/hooks';
import styled, { css } from 'styled-components';
import { ExternalLink } from 'theme/components';

import { Wiggle } from '../components/animations';
import { Body1, Box, H3 } from '../components/Generics';
import { Discord, Github, Twitter } from '../components/Icons';
import { useMenuContent } from 'components/NavBar/More/menuContent'; // Correct path based on your structure

const SocialIcon = styled(Wiggle)`
  flex: 0;
  fill: ${(props) => props.theme.neutral1};
  cursor: pointer;
  transition: fill;
  transition-duration: 0.2s;
  &:hover {
    fill: ${(props) => props.$hoverColor};
  }
`;

const RowToCol = styled(Box)`
  height: auto;
  flex-shrink: 1;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const HideWhenSmall = styled(Box)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const HideWhenLarge = styled(Box)`
  @media (min-width: 768px) {
    display: none;
  }
`;

const MenuItemStyles = css`
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.neutral2};
  stroke: none;
  transition: color 0.1s ease-in-out;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.neutral1};
    opacity: 1;
  }
`;

const StyledInternalLink = styled(Link)`
  ${MenuItemStyles}
`;

const StyledExternalLink = styled(ExternalLink)`
  ${MenuItemStyles}
`;

const ModalItem = styled.div`
  ${MenuItemStyles}
  cursor: pointer;
  user-select: none;
`;

export function Socials({ iconSize }: { iconSize?: string }) {
  return (
    <Box gap="24px">
      <SocialIcon $hoverColor="#00C32B">
        <StyledExternalLink href="https://github.com/Oracle-Swap">
          <Github size={iconSize} fill="inherit" />
        </StyledExternalLink>
      </SocialIcon>
      <SocialIcon $hoverColor="#20BAFF">
        <StyledExternalLink href="https://twitter.com/oracle_swap">
          <Twitter size={iconSize} fill="inherit" />
        </StyledExternalLink>
      </SocialIcon>
      <SocialIcon $hoverColor="#5F51FF">
        <StyledExternalLink href="https://discord.com/invite/WbDnWcRBxw">
          <Discord size={iconSize} fill="inherit" />
        </StyledExternalLink>
      </SocialIcon>
    </Box>
  );
}

export function Footer() {
  const screenIsLarge = useScreenSize()['lg'];
  const togglePrivacyPolicy = useTogglePrivacyPolicy();
  const menuContent = useMenuContent();

  return (
    <Box as="footer" direction="column" align="center" padding={screenIsLarge ? '0 40px' : '0 48px'}>
      <Box direction="row" maxWidth="1280px" gap="24px">
        <RowToCol direction="row" justify-content="space-between" gap="32px">
          <Box direction="column" height="100%" gap="64px">
            <Box direction="column" gap="10px">
              <H3>OracleSwap</H3>
            </Box>
            <HideWhenSmall>
              <Socials />
            </HideWhenSmall>
          </Box>
          {menuContent.map((section, ind1) => (
            <RowToCol key={section.title} direction="row" height="100%" gap="16px">
              <Box direction="column" gap="10px">
                <Body1>{section.title}</Body1>
                {section.items.map((item, ind2) => item.internal ? (
                  <StyledInternalLink to={item.href} key={"" + ind1 + ind2}>
                    <Trans>{item.label}</Trans>
                  </StyledInternalLink>
                ) : (
                  <StyledExternalLink href={item.href}  key={"" + ind1 + ind2}>
                    <Trans>{item.label}</Trans>
                  </StyledExternalLink>
                ))}
              </Box>
            </RowToCol>
          ))}
          <HideWhenLarge>
            <Socials />
          </HideWhenLarge>
        </RowToCol>
      </Box>
    </Box>
  );
}
