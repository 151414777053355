import { ApolloError } from '@apollo/client'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { ChainId, Percent, Token } from '@uniswap/sdk-core'
import { PortfolioLogo } from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo'
import Row from 'components/Row'
import { Table } from 'components/Table'
import { Cell } from 'components/Table/Cell'
import { ClickableHeaderRow, HeaderArrow, HeaderSortText, StyledExternalLink } from 'components/Table/styled'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { exploreSearchStringAtom } from 'components/Tokens/state'
import { MouseoverTooltip } from 'components/Tooltip'
import {
    OrderDirection,
    validateUrlChainParam,
} from 'graphql/data/util'
import { IFtsoInfo, IIFtsoInfo, ProviderProps, useMetrics } from 'hooks/useProvider'
import { getChainIdFromName } from 'hooks/useSyncChainQuery'
import { Trans } from 'i18n'
import { useAtom } from 'jotai'
import { atomWithReset, useAtomValue, useResetAtom, useUpdateAtom } from 'jotai/utils'
import { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'
import { shortenAddress } from 'utilities/src/addresses'
import { useFormatter } from 'utils/formatNumbers'
import { getExplorerLink, getFtsoExplorerLink } from 'utils/getExplorerLink'

export interface TableFTSOs {
    name: string
    address: string
    websiteLinkk: string
    rate: number
    votepower: number
    fee: number
}

export enum FtsosSortFields {
    Address = "Address",
    Name = 'Name',
    Rate = "Reward Rate",
    VotePower = "Vote Power",
    Fee = "Fee"
}

const HEADER_DESCRIPTIONS: Record<FtsosSortFields, ReactNode | undefined> = {
    [FtsosSortFields.Address]: undefined,
    [FtsosSortFields.Name]: undefined,
    [FtsosSortFields.Rate]: undefined,
    [FtsosSortFields.VotePower]: undefined,
    [FtsosSortFields.Fee]: undefined,
}

const TableWrapper = styled.div`
  margin: 0 auto;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
`

const Badge = styled(ThemedText.LabelMicro)`
  padding: 2px 6px;
  background: ${({ theme }) => theme.surface2};
  border-radius: 5px;
`

interface FtsosTableValues {
    index: number
    ftsosDescription: ReactElement
    name: string
    address: string
    websiteLink: string
    rate: number
    votepower: number
    fee: number
}

export enum FtsosTableColumns {
    Index,
    FtsosDescription,
    Name,
    Address,
    Rate,
    VotePower,
    Fee
}

function FtsosDescription({
    imageURL,
    chainId,
}: {
    imageURL: string
    chainId: ChainId
}) {
    return (
        <Row gap="sm">
            <PortfolioLogo chainId={chainId} images={[imageURL]} />
        </Row>
    )
}

export const sortMethodAtom = atomWithReset<FtsosSortFields>(FtsosSortFields.Rate)
export const sortAscendingAtom = atomWithReset<boolean>(false)

function useSetSortMethod(newSortMethod: FtsosSortFields) {
    const [sortMethod, setSortMethod] = useAtom(sortMethodAtom)
    const setSortAscending = useUpdateAtom(sortAscendingAtom)

    return useCallback(() => {
        if (sortMethod === newSortMethod) {
            setSortAscending((sortAscending) => !sortAscending)
        } else {
            setSortMethod(newSortMethod)
            setSortAscending(false)
        }
    }, [sortMethod, setSortMethod, setSortAscending, newSortMethod])
}

const HEADER_TEXT: Record<FtsosSortFields, ReactNode> = {
    [FtsosSortFields.Address]: <Trans>Address</Trans>,
    [FtsosSortFields.Name]: <Trans>Name</Trans>,
    [FtsosSortFields.Rate]: <Trans>Reward Rate</Trans>,
    [FtsosSortFields.VotePower]: <Trans>Vote Power</Trans>,
    [FtsosSortFields.Fee]: <Trans>Fee</Trans>,
}

function FtsosTableHeader({
    category,
    isCurrentSortMethod,
    direction,
}: {
    category: FtsosSortFields
    isCurrentSortMethod: boolean
    direction: OrderDirection
}) {
    const handleSortCategory = useSetSortMethod(category)
    return (
        <MouseoverTooltip disabled={!HEADER_DESCRIPTIONS[category]} text={HEADER_DESCRIPTIONS[category]} placement="top">
            <ClickableHeaderRow $justify="flex-end" onClick={handleSortCategory}>
                {isCurrentSortMethod && <HeaderArrow direction={direction} />}
                <HeaderSortText $active={isCurrentSortMethod}>{HEADER_TEXT[category]}</HeaderSortText>
            </ClickableHeaderRow>
        </MouseoverTooltip>
    )
}

export function TopFtsosTable() {
    const chainName = validateUrlChainParam(useParams<{ chainName?: string }>().chainName)
    const chainId = getChainIdFromName(chainName)
    const sortMethod = useAtomValue(sortMethodAtom)
    const sortAscending = useAtomValue(sortAscendingAtom)
    const { providers, loading, metricInfos } = useMetrics(
        { sortBy: sortMethod, sortDirection: sortAscending ? OrderDirection.Asc : OrderDirection.Desc }
    )

    const resetSortMethod = useResetAtom(sortMethodAtom)
    const resetSortAscending = useResetAtom(sortAscendingAtom)
    useEffect(() => {
        resetSortMethod()
        resetSortAscending()
    }, [resetSortAscending, resetSortMethod])

    const allDataStillLoading = loading && !providers.length

    return (
        <TableWrapper data-testid="top-ftsos-explore-table">
            <FtsosTable
                providers={[...providers]}
                loading={allDataStillLoading}
                chainId={chainId}
                metricInfos={metricInfos}
                maxWidth={1200}
            />
        </TableWrapper>
    )
}

export function FtsosTable({
    providers,
    loading,
    error,
    loadMore,
    chainId,
    maxWidth,
    maxHeight,
    hiddenColumns,
    metricInfos
}: {
    providers?: ProviderProps[]
    metricInfos: Record<string, IIFtsoInfo>
    loading: boolean
    error?: ApolloError
    loadMore?: ({ onComplete }: { onComplete?: () => void }) => void
    chainId: ChainId
    maxWidth?: number
    maxHeight?: number
    hiddenColumns?: FtsosTableColumns[]
}) {
    const { formatNumber, formatPercent } = useFormatter()
    const sortAscending = useAtomValue(sortAscendingAtom)
    const orderDirection = sortAscending ? OrderDirection.Asc : OrderDirection.Desc
    const sortMethod = useAtomValue(sortMethodAtom)
    const filterString = useAtomValue(exploreSearchStringAtom)
    const ftsoTableValues: FtsosTableValues[] | undefined = useMemo(
        () => {
            return providers?.map((provider, index) => {
                const providerSortRank = index + 1

                return {
                    index: providerSortRank,
                    ftsosDescription: (
                        <FtsosDescription imageURL={provider?.logoURI} chainId={chainId} />
                    ),
                    name: provider.name,
                    address: provider.address,
                    websiteLink: provider.url,
                    rate: metricInfos[provider.address]?.property.rewardRate,
                    votepower: metricInfos[provider.address]?.property.votePower,
                    fee: metricInfos[provider.address]?.property.fee
                }
            }) ?? []
        },
        [chainId, providers, filterString, metricInfos]
    )

    const showLoadingSkeleton = loading || !!error
    const columns = useMemo(() => {
        const columnHelper = createColumnHelper<FtsosTableValues>()
        return [
            !hiddenColumns?.includes(FtsosTableColumns.Index)
                ? columnHelper.accessor((row) => row.index, {
                    id: 'index',
                    header: () => (
                        <Cell justifyContent="center" minWidth={44}>
                            <ThemedText.BodySecondary>#</ThemedText.BodySecondary>
                        </Cell>
                    ),
                    cell: (index) => (
                        <Cell justifyContent="center" loading={showLoadingSkeleton} minWidth={44}>
                            <ThemedText.BodySecondary>{index.getValue?.()}</ThemedText.BodySecondary>
                        </Cell>
                    ),
                })
                : null,
            !hiddenColumns?.includes(FtsosTableColumns.FtsosDescription)
                ? columnHelper.accessor((row) => {
                    return <Row gap="8px" justify="flex-start">
                        <StyledExternalLink href={row.websiteLink}>
                            {row.ftsosDescription}
                        </StyledExternalLink>
                    </Row>
                }, {
                    id: 'ftsoDescription',
                    header: () => (
                        <Cell justifyContent="flex-start" minWidth={40} grow>
                            <ThemedText.BodySecondary>
                                <Trans></Trans>
                            </ThemedText.BodySecondary>
                        </Cell>
                    ),
                    cell: (ftsosDescription) => (
                        <Cell justifyContent="flex-start" loading={showLoadingSkeleton} minWidth={40} grow>
                            {ftsosDescription.getValue?.()}
                        </Cell>
                    ),
                })
                : null,
            !hiddenColumns?.includes(FtsosTableColumns.Address)
                ? columnHelper.accessor((row) => row.address, {
                    id: 'address',
                    header: () => (
                        <Cell justifyContent="flex-start" minWidth={100} grow>
                            <ThemedText.BodySecondary>
                                <Trans>Address</Trans>
                            </ThemedText.BodySecondary>
                        </Cell>
                    ),
                    cell: (address) => (
                        <Cell justifyContent="flex-start" loading={showLoadingSkeleton} minWidth={100} grow>
                            <StyledExternalLink href={getFtsoExplorerLink(chainId, address.getValue?.())}>
                                {shortenAddress(address.getValue?.())}
                            </StyledExternalLink>
                        </Cell>
                    ),
                })
                : null,
            !hiddenColumns?.includes(FtsosTableColumns.Name)
                ? columnHelper.accessor((row) => {
                    return <Row gap="8px" justify="flex-start">
                        <StyledExternalLink href={row.websiteLink}>
                            {row.name}
                        </StyledExternalLink>
                    </Row>
                }, {
                    id: 'name',
                    header: () => (
                        <Cell justifyContent="flex-start" minWidth={100} grow>
                            <FtsosTableHeader
                                category={FtsosSortFields.Name}
                                isCurrentSortMethod={sortMethod === FtsosSortFields.Name}
                                direction={orderDirection}
                            />
                        </Cell>
                    ),
                    cell: (name) => (
                        <Cell justifyContent="flex-start" loading={showLoadingSkeleton} minWidth={100} grow>
                            {name.getValue?.()}
                        </Cell>
                    ),
                })
                : null,
            !hiddenColumns?.includes(FtsosTableColumns.Rate)
                ? columnHelper.accessor((row) => row.rate, {
                    id: 'rewardRate',
                    header: () => (
                        <Cell justifyContent="flex-start" minWidth={100} grow>
                            <FtsosTableHeader
                                category={FtsosSortFields.Rate}
                                isCurrentSortMethod={sortMethod === FtsosSortFields.Rate}
                                direction={orderDirection}
                            />
                        </Cell>
                    ),
                    cell: (rate) => {
                        const rewardRate = rate.getValue?.()

                        return <Cell justifyContent="flex-start" loading={showLoadingSkeleton} minWidth={100} grow>
                            {rewardRate?.toFixed(5) ?? 0}
                        </Cell>
                    },
                })
                : null,
            !hiddenColumns?.includes(FtsosTableColumns.VotePower)
                ? columnHelper.accessor((row) => row.votepower, {
                    id: 'votepower',
                    header: () => (
                        <Cell justifyContent="flex-start" minWidth={100} grow>
                            <FtsosTableHeader
                                category={FtsosSortFields.VotePower}
                                isCurrentSortMethod={sortMethod === FtsosSortFields.VotePower}
                                direction={orderDirection}
                            />
                        </Cell>
                    ),
                    cell: (votepower) => {
                        const votePower = votepower.getValue?.()
                        return <Cell justifyContent="flex-start" loading={showLoadingSkeleton} minWidth={100} grow>
                            {votePower?.toLocaleString() ?? 0}
                        </Cell>
                    },
                })
                : null,
            !hiddenColumns?.includes(FtsosTableColumns.Fee)
                ? columnHelper.accessor((row) => row.fee, {
                    id: 'fee',
                    header: () => (
                        <Cell justifyContent="flex-start" minWidth={100} grow>
                            <FtsosTableHeader
                                category={FtsosSortFields.Fee}
                                isCurrentSortMethod={sortMethod === FtsosSortFields.Fee}
                                direction={orderDirection}
                            />
                        </Cell>
                    ),
                    cell: (fee) => (
                        <Cell justifyContent="flex-start" loading={showLoadingSkeleton} minWidth={100} grow>
                            {fee.getValue?.()}
                        </Cell>
                    ),
                })
                : null,
            // Filter out null values
        ].filter(Boolean) as ColumnDef<FtsosTableValues, any>[]
    }, [formatNumber, formatPercent, hiddenColumns, orderDirection, showLoadingSkeleton, sortMethod, providers])

    return (
        <Table
            columns={columns}
            data={ftsoTableValues}
            loading={loading}
            error={error}
            loadMore={loadMore}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
        />
    )
}
