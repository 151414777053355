import ethereumLogo from 'assets/images/ethereum-logo.png'
import { NATIVE_CHAIN_ID } from 'constants/tokens'
import { Chain } from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'

export enum TokenStandard {
  ERC20,
  ERC721,
}

export interface InteractiveToken {
  name: string
  symbol: string
  address: string
  chain: Chain
  standard: TokenStandard
  color: string
  logoUrl: string
}

export const approvedERC20: InteractiveToken[] = [
  {
    name: 'Flare',
    symbol: 'WFLR',
    address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#e62058',
    logoUrl:
      'https://assets-global.website-files.com/64c6c2033efd28e378d0a9a1/64d6c0ffe1e8ebf6aba38ace_FLRAsset%201%4010x-p-500.png',
  },
  {
    name: 'Songbird',
    symbol: 'WSGB',
    address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#2775CA',
    logoUrl:
      'https://assets-global.website-files.com/64c6c2033efd28e378d0a9a1/64d6c0e2e1e8ebf6aba36111_SGBAsset%201%4010x.png',
  },
  {
    name: 'OracleSwap',
    symbol: 'ORACLE',
    address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#e5b430',
    logoUrl:
      'https://assets-global.website-files.com/64c6c2033efd28e378d0a9a1/64d6c0903365e9c659d85672_ORCAsset%201%4010x.png',
  },
  {
    name: 'Prophet',
    symbol: 'PRO',
    address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#e5b430',
    logoUrl:
      'https://assets-global.website-files.com/64c6c2033efd28e378d0a9a1/64d6c119b28e329b9034956d_PROAsset%201%4010x.png',
  },
  {
    name: 'Vision',
    symbol: 'VIS',
    address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#e5b430',
    logoUrl:
      'https://assets-global.website-files.com/64c6c2033efd28e378d0a9a1/64d6c10e697be54b3c0b43e4_VISAsset%201%4010x.png',
  },
  {
    name: 'Ethereum',
    symbol: 'fETH',
    address: NATIVE_CHAIN_ID,
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#627EEA',
    logoUrl: ethereumLogo,
  },
  {
    name: 'USDCoin',
    symbol: 'fUSDC',
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#2775CA',
    logoUrl:
      'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
  },
  {
    name: 'Dai Stablecoin',
    symbol: 'fDAI',
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#FFAA00',
    logoUrl:
      'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png',
  },
  {
    name: 'Bitcoin',
    symbol: 'fBTC',
    address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#F7931A',
    logoUrl:
      'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
  },
  {
    name: 'Flare',
    symbol: 'WFLR',
    address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#e62058',
    logoUrl:
      'https://assets-global.website-files.com/64c6c2033efd28e378d0a9a1/64d6c0ffe1e8ebf6aba38ace_FLRAsset%201%4010x-p-500.png',
  },
  {
    name: 'Songbird',
    symbol: 'WSGB',
    address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#2775CA',
    logoUrl:
      'https://assets-global.website-files.com/64c6c2033efd28e378d0a9a1/64d6c0e2e1e8ebf6aba36111_SGBAsset%201%4010x.png',
  },
  {
    name: 'OracleSwap',
    symbol: 'ORACLE',
    address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#e5b430',
    logoUrl:
      'https://assets-global.website-files.com/64c6c2033efd28e378d0a9a1/64d6c0903365e9c659d85672_ORCAsset%201%4010x.png',
  },
  {
    name: 'Prophet',
    symbol: 'PRO',
    address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#e5b430',
    logoUrl:
      'https://assets-global.website-files.com/64c6c2033efd28e378d0a9a1/64d6c119b28e329b9034956d_PROAsset%201%4010x.png',
  },
  {
    name: 'Vision',
    symbol: 'VIS',
    address: '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d',
    chain: Chain.Ethereum,
    standard: TokenStandard.ERC20,
    color: '#e5b430',
    logoUrl:
      'https://assets-global.website-files.com/64c6c2033efd28e378d0a9a1/64d6c10e697be54b3c0b43e4_VISAsset%201%4010x.png',
  },

]

export const approvedERC721: InteractiveToken[] = [
  // {
  //   name: 'Unisocks',
  //   symbol: 'SOCKS',
  //   address: '0x65770b5283117639760beA3F867b69b3697a91dd',
  //   chain: Chain.Ethereum,
  //   standard: TokenStandard.ERC721,
  //   color: '#CD237A',
  //   logoUrl:
  //     'https://i.seadn.io/gae/70fhKktz1h38x5pHR-DGxL4zP820_kSe5iVR_dDFXEo-etqbU5H_S-qfnvot7bd2AO7VzsRlgiU1AHYVtLfKaJZx?auto=format&dpr=1&w=384',
  // },
  
]
