import { BrowserEvent, InterfaceElementName, SharedEventName } from '@uniswap/analytics-events'
import { TraceEvent } from 'analytics'
import { hideSpamAtom } from 'components/AccountDrawer/SpamToggle'
import Row from 'components/Row'
import { DeltaArrow } from 'components/Tokens/TokenDetails/Delta'
import { PortfolioToken } from 'graphql/data/portfolios'
import { getTokenDetailsURL, gqlToCurrency, logSentryErrorForUnsupportedChain } from 'graphql/data/util'
import { useAtomValue } from 'jotai/utils'
import { EmptyWalletModule } from 'nft/components/profile/view/EmptyWalletContent'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { EllipsisStyle, ThemedText } from 'theme/components'
import { Chain, PortfolioTokenBalancePartsFragment } from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import { NumberType, useFormatter } from 'utils/formatNumbers'
import { splitHiddenTokens } from 'utils/splitHiddenTokens'

import { useTokenBalancesQuery } from 'graphql/data/apollo/TokenBalancesProvider'
import { hideSmallBalancesAtom } from '../../SmallBalanceToggle'
import { ExpandoRow } from '../ExpandoRow'
import { PortfolioLogo } from '../PortfolioLogo'
import PortfolioRow, { PortfolioSkeleton, PortfolioTabWrapper } from '../PortfolioRow'
import { useAccountDrawer, useToggleAccountDrawer } from '../hooks'
import { useTokensBalances } from 'hooks/useTokenBalances'
import { useWeb3React } from '@web3-react/core'
import { getChainInfo, getChainName } from 'constants/chainInfo'
import { Token } from '@uniswap/sdk-core'

export default function Tokens() {
  const { account } = useWeb3React()
  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer()
  const hideSmallBalances = useAtomValue(hideSmallBalancesAtom)
  const hideSpam = useAtomValue(hideSpamAtom)
  const [showHiddenTokens, setShowHiddenTokens] = useState(false)

  const { tokensBal } = useTokensBalances(account)

  if (tokensBal.length === 0) {
    // TODO: consider launching moonpay here instead of just closing the drawer
    return <EmptyWalletModule type="token" onNavigateClick={toggleAccountDrawer} />
  }

  const toggleHiddenTokens = () => setShowHiddenTokens((showHiddenTokens) => !showHiddenTokens)

  return (
    <PortfolioTabWrapper>
      {tokensBal.map(
        (tokenInfo, index) =>
          tokenInfo.amount > 0 && <TokenRow key={index} {...tokenInfo} />
      )}
    </PortfolioTabWrapper>
  )
}

const TokenBalanceText = styled(ThemedText.BodySecondary)`
  ${EllipsisStyle}
`
const TokenNameText = styled(ThemedText.SubHeader)`
  ${EllipsisStyle}
`

function TokenRow({
  chainId,
  amount,
  address,
  decimal,
  token
}: {
  chainId: number,
  amount: number,
  address: string,
  decimal: number,
  token: Token
}) {
  const { formatDelta } = useFormatter()
  // const percentChange = tokenProjectMarket?.pricePercentChange?.value ?? 0

  const navigate = useNavigate()
  const toggleWalletDrawer = useToggleAccountDrawer()

  const navigateToTokenDetails = useCallback(async () => {
    navigate(getTokenDetailsURL({ address, chain: getChainName(chainId), inputAddress: address }))
    toggleWalletDrawer()
  }, [navigate, address, chainId, toggleWalletDrawer])
  const { formatNumber } = useFormatter()
  return (
    <TraceEvent
      events={[BrowserEvent.onClick]}
      name={SharedEventName.ELEMENT_CLICKED}
      element={InterfaceElementName.MINI_PORTFOLIO_TOKEN_ROW}
      properties={{ chain_id: chainId, token_name: token?.name, address: token?.address }}
    >
      <PortfolioRow
        left={
          <PortfolioLogo
            chainId={chainId}
            currencies={[token]}
            size="40px"
          />
        }
        title={<TokenNameText>{token?.name}</TokenNameText>}
        descriptor={
          <TokenBalanceText>
            {formatNumber({
              input: amount,
              type: NumberType.TokenNonTx,
            })}{' '}
            {token?.symbol}
          </TokenBalanceText>
        }
        onClick={navigateToTokenDetails}
        right={
          (
            <>
              <ThemedText.SubHeader>
                {formatNumber({
                  input: amount,
                  type: NumberType.PortfolioBalance,
                })}
              </ThemedText.SubHeader>
              {/* <Row justify="flex-end">
                <DeltaArrow delta={percentChange} />
                <ThemedText.BodySecondary>{formatDelta(percentChange)}</ThemedText.BodySecondary>
              </Row> */}
            </>
          )
        }
      />
    </TraceEvent>
  )
}
