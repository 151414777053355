// Import React and styled-components
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants';
import { useCurrentRewardEpoch } from 'hooks/useFTSOSystem';
import { floor } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Container for the main layout
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px;
  margin: 0 auto;
  margin-bottom: 16px;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  min-height: 150px;
  font-family: Arial, sans-serif;
  color: ${({ theme }) => theme.darkMode ? theme.neutral2 : "#000"};
  @media (max-width: 800px) {
    display: block;
  }
`;

// Left section containing the main message
const LeftSection = styled.div`
 @media (max-width: 800px) {
    max-width: 100%;
  }
  max-width: 45%;
  padding: 20px 0px;
`;

// Title style
const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.darkMode ? theme.neutral1 : "#000"};
`;

// Subtitle style
const Subtitle = styled.p`
  font-size: 18px;
  color: ${({ theme }) => theme.neutral2};
`;

// Right section for the reward information
const RightSection = styled.div`
  max-width: 40%;
  padding: 20px 0px;
  color: ${({ theme }) => theme.neutral2};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
   @media (max-width: 800px) {
    max-width: 100%;
  }
`;

// Epoch info container
const EpochInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

// Info row for individual data points
const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const InfoTitle = styled.div`
 display: flex;
 flex-direction:column;
 justify-content: space-between;
 gap:10px;
 margin-bottom: 20px;
 color: ${({ theme }) => theme.darkMode ? theme.neutral2 : "#000"};
`;

// Info label style
const InfoLabel = styled.span`
  color: #999;
`;

const InfoTitleLabel = styled.span`
  color: ${({ theme }) => theme.darkMode ? theme.neutral1 : "#000"};
  font-size: 18px;
  font-weight: bold;
`;

const InfoTitleValue = styled.span`
  color: #999;
  font-size: 14px;
`;

const LinkRow = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.darkMode ? theme.neutral2 : "#000"};
`;

// Styled component for the link
const StyledLink = styled.a`
  color: #e5b430;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;
// Info value style
const InfoValue = styled.span`
    font-size: 16px;
    color: #e5b430;
`;

// Progress bar container
const ProgressBarContainer = styled.div`
  position: relative;
  background: #eaeaea;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
`;

// Progress bar
const ProgressBar = styled.div<{ completed: number }>`
  width: ${props => props.completed}%;
  height: 100%;
  background: #e62058;
  transition: width 0.3s ease-in-out;
`;

// Links container
const LinksContainer = styled.div`
  margin-top: 80px;
  a {
    color: #e5b430;
    text-decoration: none;
    margin-right: 20px;
  }
`;

// Main component
const ExplorePriceSection = () => {
  const [progress, setProgress] = useState(0)
  const [remainTime, setRemainTime] = useState(0)
  const { currentEpoch, epochEnds, epochDuration, initialStatus } = useCurrentRewardEpoch()
  useEffect(() => {
    if (epochEnds === 0 || epochDuration === 0) return
    const epochStart = epochEnds - epochDuration
    const interval = setInterval(async () => {
      const now = floor(new Date().getTime() / 1000)
      const progressStatus = (now - epochStart) * 100 / epochDuration
      if (now > epochEnds) await initialStatus()
      else {
        setRemainTime(epochEnds - now)
        setProgress(progressStatus);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [epochEnds, epochDuration])
  return (
    <Container>
      <LeftSection>
        <Title> Multi-Earning Pools.</Title>
        <Subtitle>Provide Liquidity, Earn Delegation Rewards, Flare Airdrops, Superfarm Rewards & More In Multi-Earning pools!</Subtitle>
        <LinksContainer>
          <LinkRow>
            Looking to create your own superfarm or incentivize a pair?{' '}
            <br />
            <StyledLink href="/list" target="_blank">
              Get Listed
            </StyledLink>
            <StyledLink href="/incentivize" target="_blank">
              Incentivize
            </StyledLink>
          </LinkRow>
          <LinkRow>
            Need Help?{' '}
            <StyledLink href="https://discord.com/invite/WbDnWcRBxw" target="_blank">
              Join our discord
            </StyledLink>
          </LinkRow>
        </LinksContainer>
      </LeftSection>
      <RightSection>
        <EpochInfo>
          <InfoTitle>
            <InfoTitleLabel>Reward Epoch - {currentEpoch}</InfoTitleLabel>
            <InfoTitleValue>Farm allocations reset every epoch based on OSV VP.</InfoTitleValue>
          </InfoTitle>
          <InfoRow>
            <InfoLabel>Vision Token Per Block:</InfoLabel>
            <InfoValue>5 VIS</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Farm Distributions/Day:</InfoLabel>
            <InfoValue>10,000 VIS</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Total OSV VP:</InfoLabel>
            <InfoValue>100,000,000 OSV</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Total OSV Votes:</InfoLabel>
            <InfoValue>75,000,000 OSV</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Active Farms:</InfoLabel>
            <InfoValue>75</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Active SuperFarms:</InfoLabel>
            <InfoValue>25</InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Next Epoch:</InfoLabel>
            <InfoValue color="#e62058">
              {remainTime / 3600 >= 1 ? floor(remainTime / 3600) + "H " : ""}
              {(remainTime % 3600) / 60 >= 1 ? floor((remainTime % 3600) / 60) + "M " : ""}
              {floor((remainTime % 3600) % 60) + "S"}
            </InfoValue>
          </InfoRow>
          <ProgressBarContainer>
            <ProgressBar completed={progress} />
          </ProgressBarContainer>
        </EpochInfo>
      </RightSection>
    </Container>
  );
};

// Export the component as default
export default ExplorePriceSection;
