import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { Interface, formatEther } from 'ethers/lib/utils'
import { useTokenBalancesQuery } from 'graphql/data/apollo/TokenBalancesProvider'
import { supportedChainIdFromGQLChain } from 'graphql/data/util'
import { useMultipleContractSingleData } from 'lib/hooks/multicall'
import { TokenBalances } from 'lib/hooks/useTokenList/sorting'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { PortfolioTokenBalancePartsFragment } from 'uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks'
import { useDefaultActiveTokens } from './Tokens'
import { Token } from '@uniswap/sdk-core'
import { useTokenContractsConstant } from './useTokenContractsConstant'
import { Erc20Interface } from 'uniswap/src/abis/types/Erc20'
import ERC20ABI from 'uniswap/src/abis/erc20.json'

const ERC20Interface = new Interface(ERC20ABI) as Erc20Interface

export function useTokenBalances(): {
  balanceMap: TokenBalances
  balanceList: readonly (PortfolioTokenBalancePartsFragment | undefined)[]
  loading: boolean
} {
  const { chainId } = useWeb3React()
  const { data, loading } = useTokenBalancesQuery()
  return useMemo(() => {
    const balanceList = data?.portfolios?.[0]?.tokenBalances ?? []
    const balanceMap =
      balanceList?.reduce((balanceMap, tokenBalance) => {
        const address =
          tokenBalance?.token?.standard === 'ERC20'
            ? tokenBalance.token?.address?.toLowerCase()
            : tokenBalance?.token?.symbol ?? 'ETH'
        if (
          tokenBalance?.token?.chain &&
          supportedChainIdFromGQLChain(tokenBalance.token?.chain) === chainId &&
          address &&
          tokenBalance.denominatedValue?.value !== undefined
        ) {
          const usdValue = tokenBalance.denominatedValue?.value
          const balance = tokenBalance.quantity
          balanceMap[address] = { usdValue, balance: balance ?? 0 }
        }
        return balanceMap
      }, {} as TokenBalances) ?? {}
    return { balanceMap, balanceList, loading }
  }, [chainId, data?.portfolios, loading])
}

export const useTokensBalances = (account: string | undefined) => {
  const { chainId, provider } = useWeb3React()
  const [totalEthBal, setTotalEthBal] = useState(0)
  const [loading, setLoading] = useState(false)
  const activeTokensList = useDefaultActiveTokens(chainId)

  const allTokenAddresses = useMemo(
    () => Object.values(activeTokensList).map((token: Token) => token.address),
    [activeTokensList]
  )

  const accountArg = useMemo(() => [account ?? undefined], [account])

  const balancesRes = useMultipleContractSingleData(allTokenAddresses, ERC20Interface, 'balanceOf', accountArg)
  const decimalsRes = useMultipleContractSingleData(allTokenAddresses, ERC20Interface, 'decimals')

  const addressesToBalance = useMemo(() => {

    const result: {
      chainId: number,
      address: string,
      amount: number,
      decimal: number,
      token: Token
    }[] = []
    if (!chainId) return []
    for (let i = 0; i < allTokenAddresses.length; i++) {
      const callResult = balancesRes[i].result
      const decimalRes = decimalsRes[i].result
      if (!callResult?.balance || !decimalRes) continue
      const amount = parseFloat(ethers.utils.formatUnits(callResult?.balance ?? "0x0", decimalRes[0]))
      if (amount == 0) continue
      const address = allTokenAddresses[i]
      const item = {
        chainId,
        address,
        amount,
        decimal: decimalRes[0],
        token: activeTokensList[address]
      }
      result.push(item)
    }
    return result
  }, [allTokenAddresses, balancesRes, decimalsRes, chainId, activeTokensList])

  useEffect(() => {
    if (!provider || !account) return;

    provider.getBalance(account).then((res: any) => {
      const etherBalance = ethers.utils.formatEther(res);
      setTotalEthBal(parseFloat(etherBalance))
    })

  }, [provider, account])

  return {
    totalEthBal,
    tokensBal: addressesToBalance
  }
}

export const usePoolBalances = (account: string | undefined, pools: (string | undefined)[]) => {
  
  const accountArg = useMemo(() => [account ?? undefined], [account])

  const poolsAddress = useMemo(() => pools.filter((val) => !!val), [pools])

  const balancesRes = useMultipleContractSingleData(poolsAddress, ERC20Interface, 'balanceOf', accountArg)

  return useMemo(() => {
    const result: Record<string, number> = {}
    for (let i = 0; i < poolsAddress.length; i++) {
      const callResult = balancesRes[i].result
      if (!callResult?.balance) continue
      const amount = parseFloat(ethers.utils.formatEther(callResult?.balance ?? "0x0"))
      const address = poolsAddress[i]
      if (amount == 0 || !address) continue
      result[address] = amount
    }
    return result
  }, [poolsAddress, balancesRes])
}