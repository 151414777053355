import { ChainId } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { FTSO_PROVIDER_LIST } from "constants/lists";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFTSOManagerContract, useOSLPVPContract, useRewardManagerContract, useWNatContract } from "./useContract";
import { MULTICALL2_ADDRESS, useCurrentRewardEpoch, useOSLPVP } from "./useFTSOSystem";
import { RPC_PROVIDERS } from "constants/providers";
import { ContractCallContext, ContractCallResults, Multicall } from "ethereum-multicall";
import FTSO_Mananger_ABI from 'constants/abis/ftsoManager.json'
import Ftso_RewardManager_ABI from 'constants/abis/ftsoRewardManager.json'
import WNat_ABI from 'constants/abis/wNat.json'
import { formatEther, formatUnits } from "ethers/lib/utils";
import { FtsosSortFields } from "components/Ftsos/FTSOTable";
import { FtsosSortFields as PoolFTSOTableSortFields } from "pages/FTSOs/PoolFTSOTable";
import { OrderDirection } from "graphql/data/util";
import OSLPVPContract_ABI from 'constants/abis/osLPVPContract.json'


export interface ProviderProps {
    chainId: Number;
    name: string;
    description: string;
    url: string;
    address: string;
    logoURI: string;
    listed: boolean;
}

export interface IFtsoInfo {
    providerName: string;
    providerLogo: string;
    address: string;
    rewardRate: number;
    accuracy: number;
    availability: number;
    votePower: number;
    fee: number;
}

export interface IIFtsoInfo {
    item: ProviderProps;
    property: IFtsoInfo;
}

export type FtsoTableSortState = {
    sortBy: FtsosSortFields
    sortDirection: OrderDirection
}


export const DefaultFtsoInfo: IIFtsoInfo = {
    item: {
        chainId: 0,
        name: "",
        description: "",
        url: "",
        address: "",
        logoURI: "",
        listed: false,
    },
    property: {
        providerName: "",
        providerLogo: "",
        address: "",
        rewardRate: 0,
        accuracy: 100,
        availability: 100,
        votePower: 0,
        fee: 0,
    }
}

export const useProviders = () => {
    const { chainId } = useWeb3React()
    const [providers, setProviders] = useState<ProviderProps[]>([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!chainId) return;
        getting();
    }, [chainId])

    const getting = useCallback(async () => {
        setLoading(true)
        if (!chainId) return;
        fetch(FTSO_PROVIDER_LIST)
            .then(async (res) => {
                let output = await res.json();
                let providers = JSON.parse(atob(output.content)).providers;
                let _temp = providers.filter((provider: ProviderProps, index: any) => {
                    return provider.chainId === chainId && provider.listed === true;
                })
                setProviders(_temp);
                setLoading(false)
            }).catch((e) => {
                setProviders([]);
                setLoading(false)
            })
    }, [chainId])
    return {
        providers,
        loading
    };
};

export function sortProviders(providers: ProviderProps[], ftsoMetrics: Record<string, IIFtsoInfo>, sortState?: FtsoTableSortState) {
    if (!sortState) return providers
    return providers.sort((a, b) => {
        const aInfo = ftsoMetrics[a.address]?.property ?? undefined
        const bInfo = ftsoMetrics[b.address]?.property ?? undefined
        if (!aInfo || !bInfo) return 1;
        switch (sortState.sortBy) {
            case FtsosSortFields.Name:
                return sortState.sortDirection === OrderDirection.Desc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
            case FtsosSortFields.Rate:
                return sortState.sortDirection === OrderDirection.Desc ? bInfo.rewardRate - aInfo.rewardRate : aInfo.rewardRate - bInfo.rewardRate
            case FtsosSortFields.VotePower:
                return sortState.sortDirection === OrderDirection.Desc
                    ? bInfo.votePower - aInfo.votePower
                    : aInfo.votePower - bInfo.votePower
            case FtsosSortFields.Fee:
                return sortState.sortDirection === OrderDirection.Desc
                    ? bInfo.fee - aInfo.fee : aInfo.fee - bInfo.fee
            default:
                return sortState.sortDirection === OrderDirection.Desc ? bInfo.rewardRate - aInfo.rewardRate : aInfo.rewardRate - bInfo.rewardRate
        }
    })
}

export const useMetrics = (sortState?: FtsoTableSortState) => {
    const { account, chainId } = useWeb3React();
    const [metricInfos, setMetricInfos] = useState<Record<string, IIFtsoInfo>>({});
    const [totalReward, setTotalReward] = useState<number>(1);
    const [loading, setLoading] = useState(false);
    const [rewardEpochVotePowerBlock, setRewardEpochVotePowerBlock] = useState(0);
    const [totalVotePower, setTotalVotePower] = useState(1)

    const { providers, loading: providerLoading } = useProviders();
    const rewardManager = useRewardManagerContract();
    const { ftsoManagerContract, currentEpoch: currentRewardEpoch } = useCurrentRewardEpoch();
    const wNat = useWNatContract();

    const rpcProvider = useMemo(() => {
        if (!chainId) return undefined
        return RPC_PROVIDERS[chainId]
    }, [chainId])

    useEffect(() => {
        if (!wNat) return
        wNat.totalVotePower().then((res: any) => {
            setTotalVotePower(Number(formatEther(res)))
        })
    }, [wNat])

    useEffect(() => {
        if (!ftsoManagerContract || currentRewardEpoch < 1) return;
        ftsoManagerContract.getRewardEpochVotePowerBlock(currentRewardEpoch).then((res: any) => {
            setRewardEpochVotePowerBlock(res.toNumber())
        }).catch((error: any) => {
            console.log(error)
        })
    }, [ftsoManagerContract, currentRewardEpoch])

    useEffect(() => {
        if (!rewardManager || currentRewardEpoch < 1) return;

        rewardManager.getEpochReward(currentRewardEpoch).then((res: any) => {
            setTotalReward(parseFloat(formatEther(res._totalReward)))
        });
    }, [rewardManager, currentRewardEpoch])

    const fetchData = useCallback(async (chunckSize: number) => {
        if (!rewardManager ||
            !wNat ||
            currentRewardEpoch < 1 ||
            !rpcProvider ||
            providers.length < 1 ||
            rewardEpochVotePowerBlock < 1 ||
            !chainId
        ) {
            return;
        }

        let temp: Record<string, IIFtsoInfo> = {};
        let tempArr: ProviderProps[] = [];
        setLoading(true);

        const multicall = new Multicall({
            multicallCustomContractAddress: MULTICALL2_ADDRESS[chainId],
            ethersProvider: rpcProvider,
            tryAggregate: true,
        });

        const contractCallContext: ContractCallContext[] = [
            {
                reference: 'res_reward',
                contractAddress: rewardManager.address,
                abi: Ftso_RewardManager_ABI,
                calls: [...providers.map((item => { return { reference: 'res_reward', methodName: 'getStateOfRewards', methodParameters: [item.address, currentRewardEpoch] } }))]
            },
            {
                reference: 'res_fee',
                contractAddress: rewardManager.address,
                abi: Ftso_RewardManager_ABI,
                calls: [...providers.map((item => { return { reference: 'res_fee', methodName: 'getDataProviderCurrentFeePercentage', methodParameters: [item.address] } }))]
            },
            {
                reference: 'VPofAt',
                contractAddress: wNat.address,
                abi: WNat_ABI,
                calls: [...providers.map((item => { return { reference: 'VPofAt', methodName: 'votePowerOfAt', methodParameters: [item.address, rewardEpochVotePowerBlock] } }))]
            },
            {
                reference: 'VPof',
                contractAddress: wNat.address,
                abi: WNat_ABI,
                calls: [...providers.map((item => { return { reference: 'VPof', methodName: 'votePowerOf', methodParameters: [item.address] } }))]
            }
        ]

        const results: ContractCallResults = await multicall.call(contractCallContext);

        const res = results.results;


        providers.forEach((item, index) => {
            let res_reward = res.res_reward.callsReturnContext[index].returnValues;
            let res_fee = res.res_fee.callsReturnContext[index].returnValues;
            let res_vpofAt = res.VPofAt.callsReturnContext[index].returnValues;
            let res_vp = res.VPof.callsReturnContext[index].returnValues;

            if (res_reward[0].length < 1) return;
            let metric: IIFtsoInfo = { ...DefaultFtsoInfo };
            metric.item = item;
            metric.property = {
                providerName: item.name,
                providerLogo: item.logoURI,
                address: res_reward[0][0],
                rewardRate: (10000 / parseFloat(formatUnits(res_fee[0], 0)) - 1) * parseFloat(formatUnits(res_reward[1][0])) * 100 / (res_vpofAt.length > 0 ? parseFloat(formatUnits(res_vpofAt[0])) : 1),
                accuracy: 100,
                availability: 100,
                votePower: res_vp.length > 0 ? parseFloat(formatUnits(res_vp[0])) : 0,
                fee: parseFloat(formatUnits(res_fee[0], 0)) / 100,
            }
            temp[res_reward[0][0]] = metric;
            tempArr.push(item);
        })
        setLoading(false);
        setMetricInfos(temp);
    }, [rewardManager, wNat, currentRewardEpoch, rpcProvider, providers, rewardEpochVotePowerBlock, chainId])

    useEffect(() => {
        if (
            !rewardManager ||
            providers.length < 1 ||
            currentRewardEpoch < 1 ||
            totalVotePower <= 1 ||
            rewardEpochVotePowerBlock < 1 ||
            !chainId
        ) return;
        let chunckSize = 15;
        fetchData(chunckSize)
    }, [rewardManager, wNat, currentRewardEpoch, rpcProvider, providers, rewardEpochVotePowerBlock, chainId])

    const sortedProviders = useMemo(() => sortProviders(providers, metricInfos, sortState), [providers, metricInfos, sortState])

    return {
        totalReward,
        metricInfos,
        providers: sortedProviders,
        loading,
        chainId,
        rewardEpochVotePowerBlock
    }
}

export const useMetricsForPool = (pool: string | undefined, sortState?: PoolFTSOTableSortFields) => {
    const { account, chainId } = useWeb3React();
    const [myVotes, setMyVotes] = useState<{ delegatee: string, votes: number } | undefined>(undefined)
    const [delegateeInfos, setDelegateeInfos] = useState<{ address: string, rewardRate: number, fee: number, votePower: number }[]>([])
    const oslpvp = useOSLPVPContract()
    const { topDelegateeList } = useOSLPVP(pool)
    const { providers } = useProviders()
    const rewardManager = useRewardManagerContract();
    const { ftsoManagerContract, currentEpoch: currentRewardEpoch, vpBlock } = useCurrentRewardEpoch();
    const wNat = useWNatContract();

    const rpcProvider = useMemo(() => {
        if (!chainId) return undefined
        return RPC_PROVIDERS[chainId]
    }, [chainId])

    const delegateeList = useMemo(() => providers.filter((item) => item.chainId === chainId ? true : false).map((item) => item.address), [providers, chainId])
    // const delegateeList = providers.filter((item) => item.chainId === chainId ? true : false).map((item) => item.address)

    const fetchData = useCallback(async () => {
        if (!rewardManager ||
            !wNat ||
            currentRewardEpoch < 1 ||
            !rpcProvider ||
            delegateeList.length < 1 ||
            vpBlock < 1 ||
            !chainId ||
            !oslpvp ||
            !account
        ) {
            return;
        }
        const multicall = new Multicall({
            multicallCustomContractAddress: MULTICALL2_ADDRESS[chainId],
            ethersProvider: rpcProvider,
            tryAggregate: true,
        });
        const contractCallContext: ContractCallContext[] = [
            {
                reference: 'res_reward',
                contractAddress: rewardManager.address,
                abi: Ftso_RewardManager_ABI,
                calls: [...delegateeList.map((item => { return { reference: 'res_reward', methodName: 'getStateOfRewards', methodParameters: [item, currentRewardEpoch] } }))]
            },
            {
                reference: 'res_fee',
                contractAddress: rewardManager.address,
                abi: Ftso_RewardManager_ABI,
                calls: [...delegateeList.map((item => { return { reference: 'res_fee', methodName: 'getDataProviderCurrentFeePercentage', methodParameters: [item] } }))]
            },
            {
                reference: 'VPofAt',
                contractAddress: wNat.address,
                abi: WNat_ABI,
                calls: [...delegateeList.map((item => { return { reference: 'VPofAt', methodName: 'votePowerOfAt', methodParameters: [item, vpBlock] } }))]
            },
            {
                reference: 'votePower',
                contractAddress: oslpvp.address,
                abi: OSLPVPContract_ABI,
                calls: [...delegateeList.map((item => { return { reference: 'votePower', methodName: 'votePower', methodParameters: [pool, item] } }))]
            },
            {
                reference: 'myVotes',
                contractAddress: oslpvp.address,
                abi: OSLPVPContract_ABI,
                calls: [{ reference: 'myVotes', methodName: 'delegates', methodParameters: [pool, account] }]
            },
        ]

        const results: ContractCallResults = await multicall.call(contractCallContext);

        const res = results.results;

        let delegateeInfos: { address: string, rewardRate: number, fee: number, votePower: number }[] = []

        let myVotes = res.myVotes.callsReturnContext[0].returnValues;
        setMyVotes({
            delegatee: myVotes[0],
            votes: parseFloat(formatUnits(myVotes[1]))
        })

        delegateeList.forEach((item, index) => {
            let res_reward = res.res_reward.callsReturnContext[index].returnValues;
            let res_fee = res.res_fee.callsReturnContext[index].returnValues;
            let res_vpofAt = res.VPofAt.callsReturnContext[index].returnValues;
            let res_vp = res.votePower.callsReturnContext[index].returnValues;
            console.log(res_reward, res_fee, res_vpofAt, res_vp)
            if (res_reward[0].length < 1) return;
            let metricItem = {
                address: item,
                rewardRate: (10000 / parseFloat(formatUnits(res_fee[0], 0)) - 1) * parseFloat(formatUnits(res_reward[1][0])) * 100 / (res_vpofAt.length > 0 ? parseFloat(formatUnits(res_vpofAt[0])) : 1),
                votePower: res_vp.length > 0 ? parseFloat(formatUnits(res_vp[0])) : 0,
                fee: parseFloat(formatUnits(res_fee[0], 0)) / 100,
            }
            delegateeInfos.push(metricItem)
        })
        setDelegateeInfos(delegateeInfos.sort((delegatee1, delegatee2) => delegatee2.votePower - delegatee1.votePower))

    }, [rewardManager, wNat, currentRewardEpoch, rpcProvider, delegateeList, vpBlock, chainId, oslpvp, account])

    useEffect(() => {
        fetchData()
    }, [rewardManager, wNat, currentRewardEpoch, rpcProvider, delegateeList, vpBlock, chainId, oslpvp, account])

    return {
        myVotes,
        delegateeInfos
    }
}