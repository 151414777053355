import { AddressMap, ChainId, WETH9 } from "@uniswap/sdk-core";
import { useWeb3React } from "@web3-react/core";
import { isFlareChain } from "constants/chains";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDistContract, useFTSOLPManagerContract, useFTSOManagerContract, useOSLPVPContract, useOSVContract, useRewardManagerContract, useWNatContract } from "./useContract";
import { formatEther } from "ethers/lib/utils";
import {
    Multicall,
    ContractCallResults,
    ContractCallContext,
} from 'ethereum-multicall';
import { BigNumber, ethers } from 'ethers';
import FTSO_Mananger_ABI from 'constants/abis/ftsoManager.json'
import FTSO_RewardMananger_ABI from 'constants/abis/ftsoRewardManager.json'
import WNAT_ABI from 'constants/abis/wNat.json'
import { RPC_PROVIDERS } from "constants/providers";
import DIST_ABI from 'constants/abis/distributionToDelegator.json'
import { useSingleCallResult, useSingleContractMultipleData } from "lib/hooks/multicall";
import { LP_FTSO_FACET } from "constants/delegation";

export interface IFlareDropAmount {
    month: number;
    amount: number;
}

export const MULTICALL2_ADDRESS: AddressMap = {
    [ChainId.COSTON2]: "0x35060f7803eF7763b77E4EF0082bc0bCf2654154",
    [ChainId.FLARE]: '0x1240C3f2D9d20951C8d338548c6F79315cD007A3',
    [ChainId.SONGBIRD]: '0x9f3C3e294ea78319CCD16589eB9e8Ab24BBCF613',
}

export const useCurrentRewardEpoch = () => {
    const { chainId } = useWeb3React()
    const [currentEpoch, setCurrentEpoch] = useState(0);
    const [epochDuration, setEpochDuration] = useState(0);
    const [epochEnds, setEpochEnds] = useState(0)
    const [vpBlock, setVPBlock] = useState(0);

    const ftsoManagerContract = useFTSOManagerContract()

    const provider = useMemo(() => {
        if (!chainId) return undefined
        return RPC_PROVIDERS[chainId]
    }, [chainId])

    const initialStatus = useCallback(async () => {
        if (!provider || !ftsoManagerContract || !chainId) return
        const multicall = new Multicall({
            multicallCustomContractAddress: MULTICALL2_ADDRESS[chainId],
            ethersProvider: provider,
            tryAggregate: true,
        });

        const contractCallContext: ContractCallContext[] = [
            {
                reference: 'res_ftsoManager',
                contractAddress: ftsoManagerContract.address,
                abi: FTSO_Mananger_ABI,
                calls: [
                    { reference: "res_currentEpoch", methodName: "getCurrentRewardEpoch", methodParameters: [] },
                    { reference: "res_duration", methodName: "rewardEpochDurationSeconds", methodParameters: [] },
                    { reference: "res_currentEpochEnds", methodName: "currentRewardEpochEnds", methodParameters: [] },
                ]
            }
        ]
        try {
            const results: ContractCallResults = await multicall.call(contractCallContext);
            const res = results.results;
            const res_ftsoManager = res.res_ftsoManager.callsReturnContext;
            const res_currentEpoch = res_ftsoManager[0].returnValues[0];
            const res_duration = res_ftsoManager[1].returnValues[0];
            const res_currentEpochEnds = res_ftsoManager[2].returnValues[0];
            setCurrentEpoch(Number(BigNumber.from(res_currentEpoch)));
            setEpochDuration(Number(BigNumber.from(res_duration)));
            setEpochEnds(Number(BigNumber.from(res_currentEpochEnds)));
        } catch (error) {
            console.log(error)
        }
    }, [provider, ftsoManagerContract, chainId])

    useEffect(() => {
        initialStatus()
    }, [provider, ftsoManagerContract, chainId])


    useEffect(() => {
        if (!ftsoManagerContract || currentEpoch < 1) return;
        ftsoManagerContract.getRewardEpochVotePowerBlock(currentEpoch).then((res: any) => {
            setVPBlock(res.toNumber())
        }).catch((error: any) => { console.log(error) })
    }, [ftsoManagerContract, currentEpoch])

    return {
        currentEpoch,
        epochDuration,
        epochEnds,
        ftsoManagerContract,
        vpBlock,
        provider,
        initialStatus
    }
};

export const useDelegationInfo = (owner: string | undefined) => {
    const { chainId } = useWeb3React()
    const [votePower, setVotePower] = useState(0)
    const [delegatedAmount, setDelegatedAmount] = useState(0)
    const [pendingRewardOfOwner, setPendingRewardOfOwner] = useState(0)
    const [delegationsOfOwner, setDelegationsOfOwner] = useState<{ address: string, amount: number }[]>([])
    const [isSupportFTSOFacet, setIsSupportFTSOFacet] = useState(false)
    const [claimableEpochs, setClaimableEpochs] = useState([])
    const [totalClaimableReward, setTotalClaimableReward] = useState(0)

    const wNat = useWNatContract()
    const { currentEpoch, vpBlock, provider } = useCurrentRewardEpoch();
    const ftsoRewardManager = useRewardManagerContract()
    const lpManagerProxy = useFTSOLPManagerContract()

    useEffect(() => {
        if (!wNat || !owner) return
        wNat.balanceOf(owner).then((res: any) => {
            setVotePower(parseFloat(formatEther(res)))
        })
    }, [wNat, owner])

    useEffect(() => {
        if (!lpManagerProxy || !owner) return
        lpManagerProxy.facetsOfLP(owner).then((res: any) => {
            for (let index = 0; index < res.length; index++) {
                if (res[index].facetAddress.toLowerCase() === LP_FTSO_FACET.toLowerCase()) {
                    setIsSupportFTSOFacet(true)
                }
            }
        })
    }, [lpManagerProxy, owner])

    const updateClaimableEpochs = useCallback(async () => {
        if (!owner || !ftsoRewardManager) return
        ftsoRewardManager.getEpochsWithUnclaimedRewards(owner).then((res:any) => {
            setClaimableEpochs(res.map((item:any) => item.toNumber()))
        }).catch((error: any) => { console.log(error) })
    }, [ftsoRewardManager, owner])

    const getRewardState = useCallback(async () => {
        if (!provider || !ftsoRewardManager || !owner || claimableEpochs.length < 1 || !chainId) return

        const multicall = new Multicall({
            multicallCustomContractAddress: MULTICALL2_ADDRESS[chainId],
            ethersProvider: provider,
            tryAggregate: true,
        });
        const contractCallContext: ContractCallContext[] = [
            {
                reference: 'res_rewardManager',
                contractAddress: ftsoRewardManager.address,
                abi: FTSO_RewardMananger_ABI,
                calls: claimableEpochs.map(
                    epoch => ({ reference: "res_getStateOfRewards", methodName: "getStateOfRewards", methodParameters: [owner, epoch] })
                )
            }
        ]
        try {
            const results: ContractCallResults = await multicall.call(contractCallContext);
            const res = results.results;
            let totalAmount = 0;
            if (res.res_rewardManager.callsReturnContext) {
                for (let epochInd = 0; epochInd < claimableEpochs.length; epochInd++) {
                    const res_getStateOfRewards = res.res_rewardManager.callsReturnContext?.[epochInd].returnValues;
                    for (let i = 0; i < res_getStateOfRewards[1].length; i++) {
                        totalAmount += Number(formatEther(res_getStateOfRewards[1][i]));
                    }
                }
            }
            setTotalClaimableReward(totalAmount)
        } catch (error) {
            console.log(error);
        }

    }, [ftsoRewardManager, owner, claimableEpochs, provider, chainId])

    const initialStatus = useCallback(async () => {
        if (!provider || !ftsoRewardManager || !wNat || !chainId || !owner || currentEpoch < 1 || votePower < 1) return
        const multicall = new Multicall({
            multicallCustomContractAddress: MULTICALL2_ADDRESS[chainId],
            ethersProvider: provider,
            tryAggregate: true,
        });
        const contractCallContext: ContractCallContext[] = [
            {
                reference: 'res_vpToken',
                contractAddress: wNat.address,
                abi: WNAT_ABI,
                calls: [{ reference: "res_delegatesOf", methodName: "delegatesOf", methodParameters: [owner] },]
            },
            {
                reference: 'res_rewardManager',
                contractAddress: ftsoRewardManager.address,
                abi: FTSO_RewardMananger_ABI,
                calls: [
                    { reference: "res_getStateOfRewards", methodName: "getStateOfRewards", methodParameters: [owner, currentEpoch] }
                ]
            }
        ]

        try {
            const results: ContractCallResults = await multicall.call(contractCallContext);
            const res = results.results;
            const res_delegatesOf = res.res_vpToken.callsReturnContext?.[0].returnValues;
            const res_getStateOfRewards = res.res_rewardManager.callsReturnContext?.[0].returnValues;

            if (res_delegatesOf) {
                let allocated = 0;
                for (let i = 0; i < res_delegatesOf[0].length; i++) {
                    allocated += Number(BigNumber.from(res_delegatesOf[1][i])) / 10000;
                }
                setDelegatedAmount(allocated * votePower);
            }

            let totalAmount = 0;
            let tempArr = [];

            if (res_getStateOfRewards) {
                for (let i = 0; i < res_getStateOfRewards[0].length; i++) {
                    let item = {
                        address: res_getStateOfRewards[0][i],
                        amount: Number(formatEther(res_getStateOfRewards[1][i]))
                    }
                    tempArr.push(item);
                    totalAmount += item.amount;
                }
            }
            setPendingRewardOfOwner(totalAmount);
            setDelegationsOfOwner(tempArr);
        } catch (error) {
            console.log(error);
        }
    }, [provider, ftsoRewardManager, wNat, chainId, owner, currentEpoch, votePower])

    useEffect(() => {
        initialStatus()
    }, [provider, ftsoRewardManager, wNat, chainId, owner, currentEpoch, votePower])

    useEffect(() => {
        updateClaimableEpochs()
    }, [ftsoRewardManager, owner])

    useEffect(() => {
        getRewardState()
    }, [ftsoRewardManager, owner, claimableEpochs, provider, chainId])

    return {
        votePower,
        delegatedAmount,
        pendingRewardOfOwner,
        delegationsOfOwner,
        isSupportFTSOFacet,
        claimableEpochs,
        totalClaimableReward,
        updateClaimableEpochs
    }
}

export const useFlareDropInfo = (owner: string | undefined) => {
    const { chainId } = useWeb3React()
    const [currentMon, setCurrentMon] = useState(0)
    const [startMon, setStartMon] = useState(0)
    const [endMon, setEndMon] = useState(0)
    const [nextClaimableMon, setNextClaimableMon] = useState(0);
    const [claimable, setClaimable] = useState<IFlareDropAmount[]>([])
    const [totalClaimableDrop, setTotalClaimableDrop] = useState(0)

    const DistCon = useDistContract()

    const provider = useMemo(() => {
        if (!chainId) return undefined
        return RPC_PROVIDERS[chainId]
    }, [chainId])

    const initialStatus = useCallback(async () => {
        if (!provider || !DistCon || !chainId || !owner) return
        const multicall = new Multicall({
            multicallCustomContractAddress: MULTICALL2_ADDRESS[chainId],
            ethersProvider: provider,
            tryAggregate: true,
        });
        const contractCallContext: ContractCallContext[] = [
            {
                reference: 'res_dist',
                contractAddress: DistCon.address,
                abi: DIST_ABI,
                calls: [
                    { reference: "res_getClaimableMonths", methodName: "getClaimableMonths", methodParameters: [] },
                    { reference: "res_getCurrentMonth", methodName: "getCurrentMonth", methodParameters: [] },
                    { reference: "res_nextClaimableMonth", methodName: "nextClaimableMonth", methodParameters: [owner] },
                ]
            }
        ]

        try {
            const results: ContractCallResults = await multicall.call(contractCallContext);
            const res = results.results;
            const res_dist = res.res_dist?.callsReturnContext;
            if (res_dist) {
                let res_getClaimableMonths = res_dist[0].returnValues;
                let res_getCurrentMonth = res_dist[1].returnValues[0];
                let res_nextClaimableMonth = res_dist[2].returnValues[0];

                setStartMon(Number(BigNumber.from(res_getClaimableMonths[0])))
                setEndMon(Number(BigNumber.from(res_getClaimableMonths[1])))
                setCurrentMon(Number(BigNumber.from(res_getCurrentMonth)));
                setNextClaimableMon(Number(BigNumber.from(res_nextClaimableMonth)));
            }
        } catch (error) {
            console.log(error);
        }
    }, [provider, DistCon, chainId, owner])

    const getClaimableInfo = useCallback(async () => {
        if (!provider || !DistCon || startMon < 1 || endMon < 1 || nextClaimableMon < 1 || !chainId) return;

        const multicall = new Multicall({
            multicallCustomContractAddress: MULTICALL2_ADDRESS[chainId],
            ethersProvider: provider,
            tryAggregate: true,
        });

        let temp = Array.from({ length: endMon - nextClaimableMon + 1 }, (v, i) => nextClaimableMon + i);

        const contractCallContext: ContractCallContext[] = [
            {
                reference: 'res_dist',
                contractAddress: DistCon.address,
                abi: DIST_ABI,
                calls: [...temp.map((item, index) => {
                    return { reference: 'res_getClaimableAmount', methodName: 'getClaimableAmountOf', methodParameters: [owner, item] }
                })]
            }
        ]

        try {
            const results: ContractCallResults = await multicall.call(contractCallContext);
            const res = results.results;
            const res_getClaimableAmount = res.res_dist?.callsReturnContext;
            if (res_getClaimableAmount) {
                let total = 0;
                temp.forEach((item, index) => { total += parseFloat(formatEther(res_getClaimableAmount[index].returnValues[0])) })
                setClaimable(temp.map(
                    (mon: number, index: number) => ({
                        month: mon,
                        amount: parseFloat(formatEther(res_getClaimableAmount[index].returnValues[0]))
                    })
                ));
                setTotalClaimableDrop(total)
            }
        } catch (error) {
            console.log(error)
        }
    }, [DistCon, provider, owner, startMon, endMon, nextClaimableMon, chainId])

    useEffect(() => {
        initialStatus();
    }, [owner, DistCon, chainId, provider])

    useEffect(() => {
        getClaimableInfo();
    }, [DistCon, provider, owner, startMon, endMon, nextClaimableMon, chainId])

    return {
        startMon,
        endMon,
        nextClaimableMon,
        currentMon,
        claimable,
        initialStatus,
        getClaimableInfo,
        totalClaimableDrop
    }
}

export const useOSV = () => {
    const { chainId, account } = useWeb3React()
    const osvCon = useOSVContract()
    const arg = useMemo(() => [account], [account])

    const { result } = useSingleCallResult(osvCon, "balanceOf", arg)
    const { result: votesRes } = useSingleCallResult(osvCon, "accountWeight", arg)
    const { result: lockedVPRes } = useSingleCallResult(osvCon, "accountLockedWeight", arg)

    const osvCount = useMemo(() => result ? result[0].toNumber() : 0, [result])
    const accVotes = useMemo(() => votesRes ? parseFloat(formatEther(votesRes[0])) : 0, [votesRes])
    const lockedVP = useMemo(() => lockedVPRes ? parseFloat(formatEther(lockedVPRes[0])) : 0, [lockedVPRes])

    const delegatePool = useCallback(async (delegatee: string) => {
        if (accVotes === 0 || !osvCon) return
        try {
            const tx = await osvCon.delegate(delegatee)
            await tx.wait();
        } catch (error) {
            console.log(error)
        }
    }, [accVotes, osvCon])
    return {
        osvCount,
        accVotes,
        lockedVP,
        delegatePool
    }

}

export const useOSLPVP = (pool: string | undefined) => {
    const { chainId, account } = useWeb3React()
    const [topDelegateeList, setTopDelegateeList] = useState([])
    const [delegateeList, setDelegateeList] = useState([])

    const oslpvp = useOSLPVPContract()
    const arg = useMemo(() => [account], [account])

    useEffect(()=> {
        if(!pool || !oslpvp) return
        oslpvp.getTopVotedFtso(pool).then((res:any)=> {
            setTopDelegateeList(res) 
            console.log("delegateeList: ", res)         
        })
        oslpvp.getDelegateeList(pool).then((res:any)=> {
            setDelegateeList(res) 
            console.log("delegateeList: ", res)         
        })
    }, [oslpvp, pool])
    const delegate = useCallback(async (lp: string, delegatee: string) => {
        if (!account || !oslpvp) return
        try {
            const tx = await oslpvp.delegate(lp, delegatee)
            await tx.wait();
        } catch (error) {
            console.log(error)
        }
    }, [account, oslpvp])

    const undelegate = useCallback(async (lp: string) => {
        if (!account || !oslpvp) return
        try {
            const tx = await oslpvp.undelegate(lp)
            await tx.wait();
        } catch (error) {
            console.log(error)
        }
    }, [account, oslpvp])

    return {
        delegateeList,
        topDelegateeList,
        delegate,
        undelegate
    }
}